import { defineComponent } from 'vue';

import { ElHeader } from 'element-plus';
import MainHeadLogo from '../../head-comp/main-head-logo/MainHeadLogo.vue';

export default defineComponent({
	name: 'BsHeaderAuth',
	components: {
		ElHeader,
		MainHeadLogo,
	},
});