import { defineComponent } from 'vue';
import { mapState, mapActions, mapWritableState } from 'pinia';
import { useMapDesignStore } from '@/app/stores/structures/map-design-store';

import { colConfigList } from "./table-config";

import { ElTable, ElTableColumn } from "element-plus";
import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';


export default defineComponent({
	name: 'mass-static-table',
	components: {
		ElTable, ElTableColumn,
		SvgIcon,
	},
	props:{
		tableData: {
			type: Array,
			default: ()=>[],
		}
	},
	emits: ['update:tableData', 'changeTable', 'removeRow'],
	data() {
		return {
			colConfigList,
		}
	},
	methods: {
		...mapActions( useMapDesignStore, ['getTypeDesignIcon'] ),

		changeTable(data){
			this.$emit('changeTable', data);
		},
		removeRow(rowData){
			this.$emit('removeRow', rowData);
		},
		getData(data, colKey, keyProp, keyGroup){
			const rowData = data.row;
			let fieldData = rowData?.[keyProp];

			if(keyGroup) fieldData = rowData?.[keyGroup]?.[keyProp];
			if(fieldData) return fieldData;
			return '–';
		}
	},
});