import { defineComponent } from 'vue';
import { mapActions, mapState, mapWritableState } from 'pinia';
import { useRootStore } from '@/app/stores/root-store';
import { useOperatorStore } from '@/app/stores/operator-store';
import { useDesignFilterStore } from '@/app/stores/structures/design-filter-store';

import { ElBadge } from 'element-plus';
import LoaderSpinner from "@/app/components/loaders/loader-spinner/LoaderSpinner.vue";
import TableComPanel from '@/app/components/table-com-panel/TableComPanel.vue';
import GridPagination from '@/app/components/grid/grid-pagination/GridPagination.vue';
import BsBtn from '@/app/components/controls/buttons/bs-btn/BsBtn.vue';
import BtnAction from '@/app/components/controls/buttons/btn-action/BtnAction.vue';

import OperatorDetailTable from '@/app/components/operators/operator-detail-table/OperatorDetailTable.vue';
import OperMassEmployPopup from '@/app/components/modals/oper-mass-employ-popup/OperMassEmployPopup.vue';
import OperErrorPopup from '@/app/components/modals/oper-error-popup/OperErrorPopup.vue';
import UploadPopup from '@/app/components/modals/upload-popup/UploadPopup.vue';

export default defineComponent({
	name: 'operator-item-view',
	components: {
		ElBadge,
		BsBtn, BtnAction,
		LoaderSpinner, TableComPanel, GridPagination,
		OperatorDetailTable, OperMassEmployPopup,
		UploadPopup, OperErrorPopup,
	},
	data() {
		return {
			selectedTableRow: [],
			tableCommandList: [
				{
					key:'add-total-employ',
					label:'Добавить общую занятость',
				},
			],

			isShowUploadExport: false,
			isVisibleMassEmploy: false,
			isErrorPopup: false,
		}
	},
	computed: {
		...mapState(useRootStore, ['isShowSecondAside']),
		...mapState(useOperatorStore, ['isViewLoader']),
		...mapState(useDesignFilterStore, ['countFilters']),

		...mapWritableState(useOperatorStore, ['tableData']),
	},
	methods: {
		...mapActions(useRootStore, ['setShowSecondAside']),
		...mapActions(useDesignFilterStore, ['getFilterTemplate', 'getFiltersData'] ),

		async initViewData(){
			await this.getFilterTemplate();
			await this.getFiltersData();

			return;
		},
		openFilterAside(){
			this.setShowSecondAside(!this.isShowSecondAside);
		},

		changeTable(data){
			console.log(data);
			this.selectedTableRow = data;
		},

		actionCommandPanel(key){
			if(key=='add-total-employ') this.isVisibleMassEmploy = true;
		},

		exportData(file){
			console.log(file);
		}
	},
	async created() {
		await this.initViewData();
	},
});