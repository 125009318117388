import { defineComponent } from 'vue';

import { mapState, mapActions, mapWritableState } from 'pinia';
import { useNotifStore } from '@/app/stores/notification/notif-store';
import { useChatStore } from '@/app/stores/notification/chat-store';
import { useUserStore } from '@/app/stores/user-store';
import { useDetailTargetProgStore } from '@/app/stores/target-programs/detail-target-prog-store';

import { QueueList } from '@/app/utils/queue-list';
import { colorList } from '@/app/components/notification/notif-colors-data';
import api from '@/app/utils/service-api';

import { ElPopover, ElMessageBox, ElBadge } from "element-plus";
import SvgIcon from "@/app/components/svg-icon/SvgIcon.vue";
import BtnHeadNotif from "@/app/components/controls/buttons/btn-head-notif/BtnHeadNotif.vue";
import NotifMessageManager from "@/app/components/header/notif-feed/message-types/notif-message-manager/NotifMessageManager.vue";
import NotifyApprovePopup from '@/app/components/modals/notify-approve-popup/NotifyApprovePopup.vue';
import NotifMessageSystem from '@/app/components/header/notif-feed/message-types/notif-message-system/NotifMessageSystem.vue';
import LoaderSpinner from '@/app/components/loaders/loader-spinner/LoaderSpinner.vue';

const colorQueue = new QueueList({
	list: colorList,
});

export default defineComponent({
	name: 'NotifFeed',
	components: {
		ElPopover, ElBadge,
		SvgIcon, BtnHeadNotif,
		NotifMessageManager, NotifMessageSystem,
		LoaderSpinner, NotifyApprovePopup
	},
	data() {
		return {
			isVisiblePopover: false,
			nextPage: 1,
			maxPage: null,
			notificationsLengthOnPage: 10,
			isLoading: false,
			isOpenTarget: false,
			approveItem: 0,
			appovePopupType: ''
		}
	},
	computed: {
		...mapState(useNotifStore, ['notifData', 'unreadCount']),
		...mapState(useUserStore, ['user']),

		notifList(){
			const list = [];

			for(const item of this.notifData){
				if(item?.senderType=='user'){
					list.push({
						...item,
						colorData: colorQueue.getItem(),
					});
					continue;
				}
				list.push(item);
			}
			// console.log(list);

			return list;
		},

		countNotRead(){
			return this.unreadCount;
		}
	},
	methods: {
		...mapActions(useNotifStore, ['getUnreadMessages', 'setNotifData', 'setAllMessagesRead']),
		...mapActions(useDetailTargetProgStore, [ 'sendMassAgreementDecisionTProg' ]),
		...mapActions(useNotifStore, {
			getNotifUnreadCount: 'getUnreadCount',
			updateNotifUnreadCount: 'updateUnreadCount',
		}),
		...mapActions(useChatStore, {
			getChatUnreadCount: 'getUnreadCount',
			updateChatUnreadCountMessage: 'updateUnreadCountMessage',
		}),

		showApprovePopup({index, type}){
			this.isOpenTarget = true;
			this.approveItem = index;
			this.appovePopupType = type;
		},

		async clickAllCheck(){
			ElMessageBox.confirm(null, null, {
				title: 'Отметить все как прочитанные?',
				// message: 'Вы точно хотите применить изменения?',
				showClose: false,
				customClass: 'bs-message-box',
				// type: 'warning',
			}).then(async() => {
				const notificationsIds = this.notifData.map(item => item.id);
				const res = await this.setAllMessagesRead(notificationsIds)
				this.setNotifData(res);
				this.updateNotifUnreadCount(0);
			}).catch(() => {
				console.log('Отмена');
			})
		},


		async subscribeToNotifications(userId) {
			const channel = api.getPrivateChannel(`notifications_center.user.${userId}`);
			console.log('ws-private-channel', channel);

			if(channel) {
				channel
					.listen('.unread.notifications.count.event', (data) => {
						console.log('Unread notifications count:', data);
						this.updateNotifUnreadCount(data?.count);
					})
					.listen('.unread.log.chat.notifications.count.event', (data) => {
						console.log('Unread chat count:', data);
						this.updateChatUnreadCountMessage(data?.count);
					});
			}
		},


		async handleScroll(event){
			const { scrollTop, scrollHeight, clientHeight } = event.target;

			if (scrollTop + clientHeight >= scrollHeight - 300) {
				await this.loadMoreNotifications();
			}
		},


		async loadMoreNotifications() {
			if (this.isLoading) return;
			this.isLoading = true;

			try {
				if(this.nextPage === this.maxPage){
					this.isLoading = false;
					return;
				}

				const nextPageParam = this.nextPage + 1;
				const newNotifications = await api.getData({
					model: 'notifications-center/unread',
					params: { page: nextPageParam },
				});
				if(newNotifications?.length < this.notificationsLengthOnPage) {
					this.maxPage = this.nextPage;
				} else {
					this.nextPage = nextPageParam;
				}
				this.setNotifData([...this.notifData, ...newNotifications]);
			} catch (error) {
				console.error('Ошибка при загрузке уведомлений:', error);
			}

			this.isLoading = false;
		},
		onSubmit(type, comment){
			this.comment = comment;
			this.sendMassAgreementDecisionTProg(type, comment);
		},
		onCancel(type, comment){
			this.comment = comment
		}
	},
	async created(){
		console.log(this.notifData);
		this.getNotifUnreadCount();
		this.getChatUnreadCount();
		await api.initializeWebSocket();

		if(this.user?.id) this.subscribeToNotifications(this.user.id);
	},
	unmounted() {
		if (api.echo) {
			api.echo.leave(`notifications_center.user.${this.user?.id}`);
			console.log('Disconnected from notifications channel');
		}
	},
	watch: {
		isVisiblePopover(is) {
			if(is) this.getUnreadMessages();
		},
		user(user) {
			if (user?.id) {
				console.log("User data updated, subscribing to notifications...");
				this.subscribeToNotifications(user.id);
			}
		},
	},
});