import { defineComponent } from 'vue';

import BsBtn from '@/app/components/controls/buttons/bs-btn/BsBtn.vue';
import BsInput from '@/app/components/controls/bs-input/BsInput.vue';

export default defineComponent({
	name: 'SelectAddOption',
	components: {
		BsBtn, BsInput,
	},
	props: {
		findString: String
	},
	emits: {
		'createOption': (optionName: string) => typeof optionName === 'string',
		'cancel': null
	},
	data() {
		return {
			isAdded: false,
			optionName: '',
		}
	},
	methods: {
		clickAdded(){
			this.isAdded = true;
			if(this.findString) this.optionName = this.findString;
		},
		createOption(){
			// this.$emit('save', this.optionName);
			this.$emit('createOption', this.optionName);
		},
		cancel(){
			this.isAdded = false;
			this.optionName = '';
			this.$emit('cancel');
		}
	},
})