// SCSS
import './import-style-element-plus';

import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import 'air-datepicker/air-datepicker.css';

// Общие стили
import '@/assets/styles/main.scss';

// JS
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import router from '@/app/router';
import * as Sentry from "@sentry/vue";

import { DraggableDirective } from '@braks/revue-draggable';
import { ElInfiniteScroll } from 'element-plus';
import VueVirtualScroller from 'vue-virtual-scroller';
import VueYandexMetrika from 'vue3-yandex-metrika';

import App from '@/app/App.vue';

// console.clear();
const pinia = createPinia();
const app = createApp(App);

// const isDev = window.localStorage.getItem('dev');
const isLocalhost = ['localhost'].includes(window.location.hostname);
const isProd = ['erp.outplan.ru'].includes(window.location.hostname);

if(!isLocalhost) Sentry.init({
	app,
	dsn: "https://0ec07e30d0ea7d32be5273c541e56bcd@o4507458911404032.ingest.de.sentry.io/4507459127935056",
	release:'0.0.1',
	integrations: [
		Sentry.browserTracingIntegration({ router }),
		Sentry.replayIntegration(),
	],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for tracing.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,

	// Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
	tracePropagationTargets: [ /^https:\/\/outplan-test.devtestprod.ru\/api/, /^https:\/\/outplan-prod.devtestprod.ru\/api/ ],

	// Capture Replay for 10% of all sessions,
	// plus for 100% of sessions with an error
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
});

app.use(router);
app.use(pinia);
app.use(ElInfiniteScroll);
app.use(VueVirtualScroller);

if(isProd) app.use(VueYandexMetrika, {
	id: 100353333,
	router: router,
	env: process.env.NODE_ENV,
	debug: true,
	options: {
		clickmap:true,
		trackLinks:true,
		accurateTrackBounce:true,
		webvisor:true,
		trackHash:true
	},
});

app.directive('draggable', DraggableDirective);
app.mount('#app');