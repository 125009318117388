import { defineComponent } from 'vue';
import { mapActions, mapState, mapWritableState } from 'pinia';
import { useRootStore } from '@/app/stores/root-store';
import { useUserStore } from '@/app/stores/user-store';

import { ElAside } from 'element-plus';
import MainMenu from '../main-menu/MainMenu.vue';

export default defineComponent({
	name: 'BsAside',
	components: {
		ElAside,
		MainMenu,
	},
	computed: {
		...mapState(useRootStore, ['isShowSecondAside', 'mainMenuData']),

		// permissions - Вывод меню в соответствии с правами
		menuList(){
			return this.mainMenuData.map((item)=>{
				if( item.key=='pricing-policies' &&
					(this.hasRoles('MANAGER') || this.hasRoles('HEAD_OF_SALES'))
				){

					console.info('permissions - MANAGER, HEAD_OF_SALES');
					return {
						...item,
						show: false,
					};
				}

				return item;
			})
		}
	},
	methods: {
		...mapActions(useUserStore, ['hasRoles']),
	},
});