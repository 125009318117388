const statusColor = {
	free: '#67C23A',
	reserved: '#FFC473',
	busy: '#B90000',
	partSold: '#FF9E97',
	confirmed:'#6030BD',
};

const typeSide = {
	digital: '#1F3D8A',
	disabled: '#FF5129',
}

export { statusColor, typeSide };