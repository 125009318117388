import { TOption } from "../types/types-select";
import { defineComponent, PropType } from "vue";

import helper from "@/app/utils/helper";

import { ElSelectV2, ElCheckbox } from "element-plus";
import SelectAddOption from "./select-add-option/SelectAddOption.vue";

export default defineComponent({
	name: 'BsSelectV2',
	components: {
		ElSelectV2, ElCheckbox,
		SelectAddOption,
	},
	props:{
		modelValue: [ Number, String, Array ],
		showCheckBox: Boolean,
		options: {
			type: Array as PropType<TOption[]>,
			default: ()=>[],
		},
		teleported: {
			type: Boolean,
			default: true,
		},
		noMatchText: {
			type: String,
			default: 'Совпадений не найдено',
		},
		noDataText: {
			type: String,
			default: 'Нет данных',
		},
		multiple: Boolean,
		isAddedOption: Boolean,
		isCustomFilter: Boolean,
		isSorted: Boolean,
		searchThreshold: {
			type: Number,
			default: 2,
		}
	},
	emits: {
		'update:modelValue': (value: string | number | object[]) => typeof value === 'string' || typeof value === 'number' || typeof value === 'object',
		'createOption': (optionName: string) => typeof optionName === 'string',
	},
	data() {
		return {
			findString: '',
		};
	},
	computed: {
		valueData: {
			get(){
				if(this.multiple) return this.modelValue || [];
				else return this.modelValue || '';
			},
			set(val){
				if(val) this.$emit('update:modelValue', val);
				else if(this.multiple) this.$emit('update:modelValue', []);
				else this.$emit('update:modelValue', '');
			}
		},
		popoverClass(){
			const classList = ['bs-select-v2__popover'];
			if(this.showCheckBox) classList.push('--show-checkbox');

			return classList.join(' ');
		},
		filteredOptions(){
			const options = this.options;
			if(this.isSorted) options.sort((a,b) => a.label.localeCompare(b.label, 'ru', { sensitivity: 'base' }));
			if(this.findString.length < this.searchThreshold) return options;

			return options.filter((item)=>{
				const optLabel = item.label.toLowerCase().trim();
				const optValue = `${item.value}`.toLowerCase().trim();
				const findString = this.findString.toLowerCase().trim();

				return optLabel.includes(findString) || optValue.includes(findString);
			});
		},
		dynamicAttr(){
			if(this.isAddedOption || this.isCustomFilter) return {
				filterMethod: this.filterMethod,
			};

			return '';
		}
	},
	methods: {
		filterMethod(findString: string){
			helper.debounce({
				keyTimer: 'filter-select',
				duration: 200,
				action: ()=>{
					this.findString = findString;
				}
			});
		},
		async createOption(optionName: string){
			const refBsSelectV2 = this.$refs.refBsSelectV2 as HTMLDivElement | undefined;

			this.findString = '';
			await this.$nextTick();
			this.$emit('createOption', optionName);
			if(refBsSelectV2) refBsSelectV2.blur();
		},
	},
})