import { defineComponent } from 'vue';

import { ElAvatar } from 'element-plus';

export default defineComponent({
	name: 'bs-avatar',
	components: {
		ElAvatar,
	},
	props:{
		size: {
			type: [String, Number],
			default: '',
		},
		src: {
			type: String,
			default: '',
		},
		name: {
			type: String,
			default: '',
		},

		lbColor: String,
		bgColor: String,
	},
	computed:{
		title(){
			if( !this.name ) return '';
			return this.name.substring(0, 2).toUpperCase();
		}
	}
})