import { defineComponent } from 'vue';

import helper from '@/app/utils/helper';

import { ElMenu, ElMenuItem } from 'element-plus';
import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';

export default defineComponent({
	name: 'main-menu',
	components: {
		ElMenu, ElMenuItem,
		SvgIcon,
	},
	props: {
		menuList: {
			type: Array,
			default: ()=>[],
		},
	},
	data() {
		return {
			isCollapse: true,
		}
	},
	computed: {
		curRouteName(){
			const pathItems = this.$route?.path.split('/');
			let index = '';

			pathItems.shift();
			if(pathItems.length) index = pathItems[0];

			// console.log(index);
			return `/${index}`;
		}
	},
	methods: {
		clickBtnCollapse(){
			this.isCollapse = !this.isCollapse;
		},
		openMenu(){ },
		closeMenu(){ },
	},
	watch: {
		// async isCollapse(is){
		// 	helper.debounce({
		// 		keyTimer: 'menu:collapse',
		// 		duration: 200,
		// 		action: ()=>{  }
		// 	});
		// }
	},
});