<template lang="pug">

router-link.main-head-logo( to="/" )
	.main-head-logo__title АУТПЛАН
	img.main-head-logo__img( src="/assets/img/res/main-head-logo.svg" alt="main-logo-rim")

</template>

<script>
export default {
	name: 'main-head-logo'
}
</script>

<style lang="scss" src="./main-head-logo.scss"></style>
