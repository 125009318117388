<template lang="pug">

.loader-spinner
	.loader-spinner__spinner
	.loader-spinner__title( v-if="title" ) {{ title }}
	.loader-spinner__num( v-if="progress" ) {{ progress }}%

</template>

<script>
export default {
	name: 'loader-spinner',
	props: ['title', 'progress'],
}
</script>
<style lang="scss" src="./loader-spinner.scss"></style>
