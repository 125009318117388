import { defineStore } from 'pinia';
import { useRootStore } from '../root-store';
import { useMapDesignStore } from './map-design-store';
import { useFiltersStore } from '../filters-store';
import dayjs from 'dayjs';

import api from '@/app/utils/service-api';
import helper from '@/app/utils/helper';
import errorService from '@/app/utils/error-service';

import getFilterTemplate from '@/mock-server/data/get-filter-template';

export const useDesignFilterStore = defineStore('designFilterStore', {
	state() {
		return {
			isFilterDataLoad: true,
			isOpenSaveFiltertempForm: false,

			totalFilters: null,
			curPeriodData: { from: '', to: '' },

			settingList: [
				{
					isCheck: true,
					key: 'address-program',
					type:'select-single',
					label: 'Использовать АП',
					keyData: 'targetProgram',
					keyBack: 'targetProgram',
				},
				{
					isCheck: true,
					key: 'city',
					type:'select-multi',
					label: 'Город',
					keyData: 'city',
					keyBack: 'city',
				},
				{
					isCheck: true,
					key: 'search-gid',
					type: 'value',
					label: 'Выборка по гидам',
					keyData: 'searchGidData',
					keyBack: 'gids',
				},
				{
					isCheck: true,
					key: 'place-period',
					type: 'date',
					label: 'Период размещения',
					keyData: 'placePeriodDate',
					keyBack: 'from_to',
				},
				// {
				// 	isCheck: true,
				// 	key: 'countFreeDays',
				// 	type: 'value',
				// 	label: 'Количество свободных дней от',
				// 	keyData: 'countFreeDays',
				// 	keyBack: 'countFreeDays',
				// },
				{
					isCheck: true,
					key: 'structureCategory',
					type: 'select-multi',
					label: 'Категория РК',
					keyData: 'structureCategory',
					keyBack: 'structureCategory',
				},
				{
					isCheck: true,
					key: 'structureType',
					type: 'select-multi',
					label: 'Тип рекламной конструкции',
					keyData: 'structureType',
					keyBack: 'structureType',
				},
				{
					isCheck: true,
					key: 'side-size',
					type: 'select-multi',
					label: 'Размер РС (Ш x Д, м)',
					keyData: 'size',
					keyBack: 'size',
				},
				{
					isCheck: true,
					key: 'side-mech',
					type: 'checkbox-group',
					label: 'Механизм РС',
					keyData: 'mechanism',
					keyBack: 'mechanism',
				},
				{
					isCheck: true,
					key: 'lighted',
					type: 'checkbox-group',
					label: 'Освещение',
					keyData: 'lighted',
					keyBack: 'lighted',
				},
				// {
				// 	isCheck: true,
				// 	key: 'status-groups',
				// 	type: 'checkbox-group',
				// 	label: 'Группы статусов',
				// 	keyData: 'freedom',
				// 	keyBack: 'freedom',
				// },
				// bookingStatus
				{
					isCheck: true,
					key: 'bookingStatus',
					type: 'select-multi',
					label: 'Статус',
					keyData: 'bookingStatus',
					keyBack: 'bookingStatus',
				},
				{
					isCheck: true,
					key: 'serviceStatus',
					type: 'select-multi',
					label: 'Сервисный статус',
					keyData: 'serviceStatus',
					keyBack: 'serviceStatus',
				},
				// {
				// 	isCheck: true,
				// 	key: 'countFreeSide',
				// 	type: 'value',
				// 	label: 'Количество свободных РП от',
				// 	keyData: 'countFreeSide',
				// 	keyBack: 'freeSurfaceCount',
				// },
				{
					isCheck: true,
					key: 'operator',
					type: 'select-multi',
					label: 'Оператор',
					keyData: 'operator',
					keyBack: 'operator',
				},
				{
					isCheck: true,
					key: 'owner',
					type: 'select-multi',
					label: 'Собственник',
					keyData: 'owner',
					keyBack: 'owner',
				},
				{
					isCheck: true,
					key: 'ots',
					type: 'compare',
					label: 'OTS',
					keyData: 'otsData',
					keyBack: 'ots',
				},
				{
					isCheck: true,
					key: 'grp',
					type: 'compare',
					label: 'GRP',
					keyData: 'grpData',
					keyBack: 'grp',
				},
				{
					isCheck: true,
					key: 'net',
					type: 'checkbox-group',
					label: 'Сеть',
					keyData: 'net',
					keyBack: 'net',
				},
				{
					isCheck: true,
					key: 'direction',
					type: 'checkbox-group',
					label: 'Направление стороны',
					keyData: 'direction',
					keyBack: 'direction',
				},
				{
					isCheck: true,
					key: 'tag',
					type: 'select-multi',
					label: 'Тэги',
					keyData: 'tag',
					keyBack: 'tag',
				},
				// title - Настройки для Digital
				{
					isCheck: true,
					key: 'setting-title',
					label: 'Настройки для Digital',
					keyData: '',
					keyBack: '',
				},
				{
					isCheck: true,
					key: 'out-frequency',
					type: 'select-single',
					label: 'Выходов в час',
					keyData: 'showsInHour',
					keyBack: 'showsInHour',
				},
				{
					isCheck: true,
					key: 'digital-block-size',
					type: 'select-multi',
					label: 'Размер digital блока (сек)',
					keyData: 'blockDuration',
					keyBack: 'blockDuration',
				},
				{
					isCheck: true,
					key: 'digital-slot-size',
					type: 'select-multi',
					label: 'Размер digital слота (сек)',
					keyData: 'slotDuration',
					keyBack: 'slotDuration',
				},
				{
					isCheck: true,
					key: 'digital-video-length',
					type: 'value',
					label: 'Длина ролика (сек)',
					keyData: 'digitalVideoLength',
					keyBack: 'duration',
				},
				{
					isCheck: true,
					key: 'wifiTrap',
					type: 'select-single',
					label: 'WiFi ловушка',
					keyData: 'wifiTrap',
					keyBack: 'wifiTrap',
				},

				// title - группа фильтров Остальное
				{
					isCheck: true,
					key: 'setting-title',
					label: 'Остальное',
					keyData: '',
					keyBack: '',
				},
				{
					isCheck: true,
					key: 'minStatusFree',
					type: 'value',
					label: 'Месяцев в свободе',
					keyData: 'minStatusFree',
					keyBack: 'minStatusFree',
				},
				{
					isCheck: true,
					key: 'maxStatusReserved',
					type: 'value',
					label: 'Месяцев в резерве',
					keyData: 'maxStatusReserved',
					keyBack: 'maxStatusReserved',
				},
				{
					isCheck: true,
					key: 'maxStatusApproved',
					type: 'value',
					label: 'Месяцев в занятости',
					keyData: 'maxStatusApproved',
					keyBack: 'maxStatusApproved',
				},
				{
					isCheck: true,
					key: 'priceBefore',
					type: 'compare',
					label: 'Прайс/месяц (без НДС)',
					keyData: 'priceBefore',
					keyBack: 'priceBefore',
				},
			],

			template:{ value: '', options: [] }, // Шаблон фильтра
			targetProgram:{ value: '' },

			/** @type {{ value: string | number | string[] | number[] }} */
			city:{ value: '' }, // Города

			// Тип периодов размещения
			placePeriodType: {
				value: 'month',
				options: [
					{
						value: 'month',
						label: 'По месяцам',
					},
					{
						value: 'day',
						label: 'По дням',
					},
				],
			},

			placePeriodDate: { from: '', to: '' }, // Период размещения
			countFreeDays: '', // Количество свободных дней от

			compareOptions: [
				// {
				// 	label: '=',
				// 	value: '='
				// },
				{
					label: '<',
					value: '<'
				},
				{
					label: '>',
					value: '>'
				},
			],

			otsData: { input: '', select: '' },
			grpData: { input: '', select: '' },
			structureCategory: { value: '' }, // Категория рекламной конструкции
			structureType: { value: '' }, // Тип рекламной конструкции
			size: { value: '' }, // Размер РС
			mechanism: { value: [] }, // Механизм РС
			lighted: { value: [] }, // Освещение

			freedom: { value: [] }, // Группы статусов
			/** @type {{ value: string | number | string[] | number[] }} */
			bookingStatus:{ value: '' }, // Статус
			serviceStatus:{ value: ['working'] }, // Сервисный статус
			countFreeSide: '', // Кол-во свободных РП
			operator: { value: '' }, // Оператор
			owner: { value: '' }, // Собственник

			net: { value: [] },
			direction: { value: [] }, // Направление стороны

			// Группа фильтров - Digital
			showsInHour: { value: '' }, // Выходов в час
			blockDuration: { value: '' }, // Размер диджитал блока
			slotDuration: { value: '' }, // Размер digital слота
			digitalVideoLength: '', 	// Длина ролика
			wifiTrap: { value: '' },
			tag: { value: '' },

			searchGidData: '', // поиск по гидам

			// Группа фильтров - Остальное
			minStatusFree: '', // Месяцев в свободе не менее
			maxStatusReserved: '', // Месяцев в резерве не более
			maxStatusApproved: '', // Месяцев в занятости не более
			priceBefore: { select: '', input: '' }, // Прайс/месяц (без НДС)
		};
	},

	getters: {
		settingsShowMap(state){
			let map = {};
			for(let item of state.settingList){
				const { key } = item;
				map[key] = item.isCheck;
			}
			return map;
		},
		countFilters(){
			let total = 0;
			const checkedParams = this.settingList.filter(item=>item.isCheck && item.keyBack);
			const addSelectParams = ({ keyBack, paramData }) => {
				const { value } = paramData;
				if(value?.length || (!Array.isArray(value) && (value || value===0))) total++;
			}

			for(let { key, keyData, keyBack } of checkedParams){
				const paramData = this[keyData];
				if(paramData!==0 && !paramData) continue;

				// селекты
				if( [ 'city', 'structureCategory', 'structureType', 'size', 'serviceStatus', 'bookingStatus',
					'operator', 'owner', 'tag', 'showsInHour', 'blockDuration',
					'slotDuration', 'targetProgram',
					'direction', 'mechanism', 'lighted', 'freedom', 'net',
					'wifiTrap',
				].includes(keyData)) addSelectParams({ keyBack, paramData });

				// инпуты
				else if(['countFreeDays', 'countFreeSide', 'addTimeSide',
					'digitalVideoLength',
					'minStatusFree', 'maxStatusReserved', 'maxStatusApproved',
				].includes(keyData)) total++;

				// чекбоксы
				// else if([ 'wifiTrap' ].includes(keyData)) total++;

				else if(keyData=='searchGidData') total++;

				else if(keyData=='placePeriodDate'){
					const { from, to } = paramData;
					if( from || to ) total++;
				}
				// compare
				else if(['otsData', 'grpData', 'priceBefore'].includes(keyData)){
					const { select, input } = paramData;
					if( input ) total++;
				}
			}

			return total;
		},
		ots(){
			const { select, input } = this.otsData;
			if( input ) return `${select} ${input}`.trim().split(' ');
			return '';
		},
		grp(){
			const { select, input } = this.grpData;
			if( input ) return `${select} ${input}`.trim().split(' ');
			return '';
		}
	},
	actions: {
		buildPlacePeriodData(){
			const fromDate = dayjs().add(1, 'months').date(1);
			const monthDays = dayjs(fromDate).daysInMonth();
			const toDate = dayjs(fromDate).date(monthDays);

			this.placePeriodDate = {
				from: fromDate.format('YYYY-MM-DD'),
				to: toDate.format('YYYY-MM-DD'),
			}

			return this.placePeriodDate;
		},

		buildFilterTemplate(data){
			const options = data.map(item=>({
				value: item.id,
				label: item.name,
				data: item.filters,
			}));

			this.template.options = options;
		},

		async getFilterTemplate(){
			// this.isFilterDataLoad = true;
			try {
				let res = await api.getData({ model: 'filter-template' });
				console.log('getFilterTemplate', res);

				// res = getFilterTemplate;
				this.buildFilterTemplate(res?.data);

				// this.isFilterDataLoad = false;
				return res?.data;
			} catch (error) {
				console.error(error);
			}

			// this.isFilterDataLoad = false;
			return;
		},

		getSubmitFilterData(){
			const data = {};
			const checkedParams = this.settingList.filter(item=>item.isCheck && item.keyBack);
			const addSelectParams = ({ keyBack, paramData, isDigital }) => {
				const { value } = paramData;
				if(value?.length || (!Array.isArray(value) && (value || value===0))){
					if(isDigital){
						if(!data?.['digital']) data['digital'] = {};
						data['digital'][keyBack] = value;
					}
					else data[keyBack] = value;
				}
			};

			for(let { key, keyData, keyBack } of checkedParams){
				const paramData = this[keyData];
				if(paramData!==0 && !paramData) continue;

				// селекты
				if([ 'city', 'structureCategory', 'structureType', 'size', 'serviceStatus', 'bookingStatus',
					'owner', 'operator', 'tag', 'targetProgram',
					'direction', 'mechanism', 'lighted', 'freedom', 'net'
				].includes(keyData)) addSelectParams({ keyBack, paramData });

				// digital селекты
				else if(['showsInHour', 'blockDuration', 'slotDuration', 'wifiTrap'].includes(keyData)){
					addSelectParams({ keyBack, paramData, isDigital: true });
				}

				// инпуты
				else if([ 'countFreeDays', 'countFreeSide', 'addTimeSide',
					'minStatusFree', 'maxStatusReserved', 'maxStatusApproved',
				].includes(keyData)) data[keyBack] = paramData;

				// digital инпуты
				else if(['digitalVideoLength'].includes(keyData)){
					if(!data?.['digital']) data['digital'] = {};
					data['digital'][keyBack] = paramData;
				}

				// чекбокс
				// else if(['wifiTrap'].includes(keyData)) data[keyBack] = !!paramData;

				else if(keyData=='searchGidData'){
					const { gids, gidSearchBy, allowPartialMatch } = paramData;
					if(gids?.length){
						data['gids'] = gids;
						data['gidSearchBy'] = gidSearchBy;
						data['allowPartialMatch'] = !!allowPartialMatch;
					}
				}

				else if(keyData=='placePeriodDate'){
					let { from, to } = paramData;
					if( from=='Invalid Date') from = '';
					if( to=='Invalid Date' ) to = '';

					this.curPeriodData = helper.clone(paramData);
					if( from || to ) data[keyBack] = `${from || ''};${to || ''}`;
				}

				else if(['otsData', 'grpData', 'priceBefore'].includes(keyData)){
					// console.log(keyData, keyBack, paramData);
					const { select, input } = paramData;
					if( select || input ) data[keyBack] = [ select || '', input || '' ];
				}

			}

			console.log('getSubmitFilterData', data);
			return data;
		},

		setFilterField(filters){
			if(!filters) return;

			console.log(filters);
			const addSelectParams = ({ keyBack, value }) => {
				const findItem = this.settingList.find(item=>item.keyBack==keyBack);
				const keyData = findItem?.keyData;
				if(keyData && this?.[keyData]){
					if(value === 0) this[keyData].value = value;
					else this[keyData].value = value || '';
				}
			}

			for(let [key, value] of Object.entries(filters)){
				// селекты
				if( [ 'targetProgram', 'city', 'structureCategory', 'structureType', 'size',
					'serviceStatus', 'bookingStatus', 'operator','owner', 'tag',
					'digital',
					// 'showsInHour', 'blockDuration', 'slotDuration', 'wifiTrap',
					'direction', 'mechanism', 'lighted', 'freedom', 'net',
				].includes(key)){
					if(key=='digital'){
						for( const [ key, digitalValue ] of Object.entries(value) ){
							if(['showsInHour', 'blockDuration', 'slotDuration', 'wifiTrap'].includes(key)){
								addSelectParams({ keyBack: key, value: digitalValue });
							}
						}
					}
					else addSelectParams({ keyBack: key, value });
				}

				// инпуты
				if([ 'countFreeDays', 'freeSurfaceCount', 'digital',
					'priceBefore', 'minStatusFree', 'maxStatusReserved', 'maxStatusApproved',
				].includes(key)){
					const findItem = this.settingList.find(item=>item.keyBack==key);
					const keyData = findItem?.keyData;

					console.log(key, keyData, value);
					if( key=='digital' ){
						for( const [ key, digitalValue ] of Object.entries(value) ){
							if(['duration'].includes(key)){
								const findItem = this.settingList.find(item=>item.keyBack==key);
								const keyData = findItem?.keyData;
								console.log(key, keyData, digitalValue);

								if(keyData && this?.[keyData]!==undefined) this[keyData] = digitalValue || '';
							}
						}
					}
					else if(keyData && this?.[keyData]!==undefined){
						if(value === 0) this[keyData] = '0';
						else this[keyData] = value || '';
					}
				}

				// чекбоксы
				// else if(['wifiTrap'].includes(key)){
				// 	const findItem = this.settingList.find(item=>item.keyBack==key);
				// 	const keyData = findItem?.keyData;

				// 	if(keyData && this?.[keyData]!==undefined) this[keyData] = !!value;
				// }

				else if(key=='from_to'){
					const [ from, to ] = value.split(';');
					if(from) this.placePeriodDate.from = from;
					if(to) this.placePeriodDate.to = to;

					this.curPeriodData = helper.clone(this.placePeriodDate);
				}

				else if(['ots', 'grp', 'priceBefore'].includes(key)){
					if(!value) continue;

					let fieldKey = key;
					if(['ots', 'grp'].includes(key)) fieldKey = `${key}Data`;

					if(Array.isArray(value)){
						if(value?.length>1){
							const [select, input] = value;
							this[fieldKey] = { select, input };
						}
						else if(value?.length==1){
							const [input] = value;
							this[fieldKey] = { select: '', input };
						}
						else this[fieldKey] = { select: '', input: '' };
					} else {
						const { select, input } = value;
						if( select || input ) this[fieldKey] = { ...value };
					}
					// console.log(key, value);
				}
				else if(key=='gids'){
					const { gids, gidSearchBy, allowPartialMatch } = filters;
					this.searchGidData = {
						gids, gidSearchBy,
						allowPartialMatch: !!allowPartialMatch,
					};
				}
			}
		},

		clearFilter(){
			const mapDesignStore = useMapDesignStore();

			console.log('clearFilter');
			for(const item of this.settingList){
				const { type, keyData } = item;
				const filterData = this?.[keyData];
				if(!keyData || !filterData) continue;

				// console.log('clearItem', { keyData, filterData });
				if(type == 'select-single') filterData.value = '';
				else if(type == 'value') this[keyData] = '';
				else if(type == 'select-multi') filterData.value = [];
				else if( type == 'checkbox-group' ){
					if(filterData?.value) filterData.value = [];
					else for(const item of filterData){
						item.isCheck = false;
					}
				}
				else if(type == 'boolean') filterData.value = false;
				else if( keyData == 'placePeriodDate' ){
					this.placePeriodType.value = 'month';
					this.buildPlacePeriodData();
				}
				else if(type == 'compare'){
					this[keyData] = { select: '', input: '' };
				}

				this.template.value = '';
				this.serviceStatus.value = ['working']; // Не сбрасывать сервисный статус работает
			}

			if(mapDesignStore?.searchMarker){
				mapDesignStore.searchMarker = null;
				$$(window).trigger('map_search_panel:clear');
			}
		},

		async saveFilterTemplate(name){
			const data = this.getSubmitFilterData();
			console.log('saveFilterTemplate', name, data);
			try {
				const res = await api.postData({
					model: 'filter-template',
					data: {
						name,
						filters: data,
					}
				});

				if(res?.data){
					const { id, name, filters } = res.data;
					this.template.value = id;
					this.template.options.unshift({
						value: id,
						label: name,
						data: filters,
					});
				}

				return res?.data;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({ error });
			}

			return;
		},
		async updateFilterTemplate(id){
			console.log('updateFilterTemplate');

			if(!id) return;
			const data = this.getSubmitFilterData({ isAllFields: true });

			try {
				const res = await api.putData({
					model: 'filter-template',
					id,
					data: {
						filters: data,
					}
				});
				console.log(res);

				return res?.data;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({ error });
			}

			return;
		},
		async removeFilterTemplate(id){
			console.log('removeFilterTemplate');

			if(!id) return;
			try {
				const res = await api.deleteData({
					model: 'filter-template',
					id,
				});
				console.log(res);

				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({ error });
			}

			return;
		},

		/**
		 * Поиск по гидам
		 * @param list - массив гидов
		 */
		async getCheckGids(data){
			// const strSearch = list.join(';');
			this.isFilterDataLoad = true;
			try {
				let res = await api.postData({
					model: 'check-gids',
					data,
				});

				console.log(res);
				this.isFilterDataLoad = false;
				return res;
			} catch (error) {
				console.error(error);
			}
			this.isFilterDataLoad = false;
			return;
		}
	},
});