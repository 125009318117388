import { IQueueItem, IManager } from '@/types/types-global';

import { defineComponent, PropType } from 'vue';
import { mapState, mapActions, mapWritableState } from 'pinia';
import { useRootStore } from '@/app/stores/root-store';

import dayjs from 'dayjs';
import helper from "@/app/utils/helper";

import { ElTag, ElMessage } from "element-plus";
import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';

export default defineComponent({
	name: 'side-queue-item',
	components: {
		ElTag, SvgIcon,
	},
	props: {
		yearMonth: {
			type: String,
			default: '',
		},
		data: {
			type: Object as PropType<IQueueItem>,
			default: ()=>({}),
		},
	},
	data() {
		return {

		}
	},
	computed: {
		...mapState(useRootStore, ['statusMap']),

		tagType(){
			const { status } = this.data ?? {};
			if( ['Подтверждено','Врем. подтв.'].includes(status)) return 'primary';
			if( ['Резерв'].includes(status)) return 'warning';
			if(['Занято', 'Недоступно'].includes(status)) return 'danger';

			return 'success';
		},
		statusLabel(){
			const status = this.data?.status;
			let label = this.statusMap?.[status];

			if(!label) label = this.statusMap['free'];
			// console.log(this.data);

			return label;
		},
		time(){
			return this.data?.time ?? '';
		},
		userInfo(){
			return this.data?.manager ?? {} as IManager;
		},
		formatPeriods(){
			const periods = this.data.periods;
			const monthPeriods = periods?.[this.yearMonth];
			let title = '';

			if(monthPeriods?.length){
				for(const period of monthPeriods){
					const [ from, to ] = period;
					const formatFrom = dayjs(from).format('D');
					const formatTo = dayjs(to).format('D');

					title += `${formatFrom}-${formatTo}`;
					if(monthPeriods.length>1) title += ', ';
				}
			}

			return title;
		}
	},
	methods: {
		async clickPhone(){
			try {
				const { phone } = this.userInfo;
				const res = await helper.copyTextBuffer(phone);

				ElMessage({
					type: 'success',
					message: 'Номер телефона успешно скопирован',
				});
			} catch (error) {
				ElMessage({
					type: 'error',
					message: 'Номер телефона не удалось скопировать',
				});
			}

			console.log('copyPhone');
		},
		async clickEmail(){
			try {
				const { email } = this.userInfo;
				const res = await helper.copyTextBuffer(email);

				ElMessage({
					type: 'success',
					message: 'Email успешно скопирован',
				});
			} catch (error) {
				ElMessage({
					type: 'error',
					message: 'Email не удалось скопировать',
				});
			}

			console.log('copyEmail');
		},
		async clickTelegram(){
			try {
				const { telegram } = this.userInfo;
				const link = `https://t.me/${telegram}`;
				const res = await helper.copyTextBuffer(link);

				ElMessage({
					type: 'success',
					message: 'Ссылка успешно скопирована',
				});
			} catch (error) {
				ElMessage({
					type: 'error',
					message: 'Ссылку не удалось скопировать',
				});
			}

			console.log('copyTelegramLink');
		}
	},
})