import { Component, Vue, toNative, Prop, Watch } from 'vue-facing-decorator';

import dayjs from 'dayjs';
import numeral from 'numeral';

import occ from '@/app/utils/occupancy';
import helper from '@/app/utils/helper';

import { BaseEmployInfo } from '../base-employ-info';
import SurfaceMonthItem from '../surface-month-item/SurfaceMonthItem.vue';

import { TEmployPeriod } from '../types/types-employ-info';

@Component({
	name: 'EmployInfoDigitalV2',
	components: {
		SurfaceMonthItem,
	},
})
class EmployInfoDigitalV2 extends BaseEmployInfo {
	@Prop({ type: Number, default: 5 }) duration!: number;
	@Prop({ type: Number, default: 60 }) frequency!: number;
	@Prop({ type: Object }) digitalData!: object;

	override get occupancy(){
		const { digitalOccupancy, occupanciesDaily } = this.data as { digitalOccupancy, occupanciesDaily };
		// const { blockDuration, slotDuration } = this.digitalData;
		const { duration, frequency } = this;
		const placePeriods: Record<string, string[]> = helper.splitPeriodByMonths(this.period.from, this.period.to) as Record<string, string[]>;
		let occupancy = {};
		const maxTimeDay = 24*60*60; // Количество секунд в дне

		// если приходит пустой градусник или в виде пустого массива
		if (!occupanciesDaily || (Array.isArray(occupanciesDaily) && !occupanciesDaily?.length)) {
			for (const item of this.monthList) {
				const yearMonth = item.date;
				const added = this.isCheckPeriod(yearMonth);

				let valueTwo = '';

				const countDay = dayjs(yearMonth).daysInMonth();
				const countHoursMonth = countDay*24;
				const countShowsMonth = (countDay*maxTimeDay)/duration;
				const avgShowsInHour = Math.ceil(countShowsMonth/countHoursMonth);

				if(countShowsMonth) valueTwo = numeral(countShowsMonth).format('0a');

				occupancy[yearMonth] = {
					status: 'free',
					valueOne: avgShowsInHour,
					valueTwo,
					added,
				};
			}
		}
		else for(const month of this.monthList){
			const yearMonth = month.date;
			const monthData = occupanciesDaily?.[yearMonth];
			const added = this.isCheckPeriod(yearMonth);
			const countDay = dayjs(yearMonth).daysInMonth();

			if(!monthData){
				let valueTwo = '';
				const countHoursMonth = countDay*24;
				const countShowsMonth = (countDay*maxTimeDay)/duration;
				const avgShowsInHour = Math.ceil(countShowsMonth/countHoursMonth);

				if(countShowsMonth) valueTwo = numeral(countShowsMonth).format('0a');

				occupancy[yearMonth] = {
					status: 'free',
					valueOne: avgShowsInHour,
					valueTwo,
					added,
				};
				continue;
			}

			let totalFree = 0;
			let totalReserved = 0;
			let data = {};
			let valueOne = '';
			let valueTwo = '';
			let statusCalendar: Record<string, string> = null;
			let monthStatusSet: Set<string> = null;

			const placePeriodData = placePeriods?.[yearMonth];
			if(placePeriodData){
				monthStatusSet = new Set();
				statusCalendar = occ.getDigitalMonthCalendar({ duration, frequency, monthData }, (dayData)=>{
					const { dayDate, free, reserved, busy, unavailable, status } = dayData;
					if(!placePeriodData.includes(dayDate)) return;

					totalFree += free;
					totalReserved += reserved;
				});
				for(const dayDate of placePeriodData){
					const status = statusCalendar?.[dayDate];
					if(status) monthStatusSet.add(status);
				}
			} else {
				statusCalendar = occ.getDigitalMonthCalendar({ duration, frequency, monthData }, (dayData)=>{
					const { free, reserved, busy, unavailable, status } = dayData;
					totalFree += free;
					totalReserved += reserved;
				});
				monthStatusSet = new Set(Object.values(statusCalendar));
			}

			// console.log({monthStatusSet});
			if(monthStatusSet.size == 1){
				if(monthStatusSet.has('free')){
					// const countHoursMonth = totalFree/(60*60);
					const countShowsMonth = totalFree/duration;
					// const avgShowsInHour = Math.ceil(countShowsMonth/countHoursMonth);
					const avgShowsInHour = Math.ceil(countShowsMonth/(24*countDay));
					if(countShowsMonth) valueTwo = numeral(countShowsMonth).format('0a');

					data = {
						status: 'free',
						valueOne: avgShowsInHour,
						valueTwo,
					};
				}
				else if(monthStatusSet.has('reserved')){
					// const countHoursMonth = totalReserved/(60*60);
					const countShowsMonth = totalReserved/duration;
					// const avgShowsInHour = Math.ceil(countShowsMonth/countHoursMonth);
					const avgShowsInHour = Math.ceil(countShowsMonth/(24*countDay));
					if(countShowsMonth) valueTwo = numeral(countShowsMonth).format('0a');

					data = {
						status: 'reserved',
						valueOne: avgShowsInHour,
						valueTwo,
					};
				}
				else if(monthStatusSet.has('free-reserved')){
					let valueTwoFree = '';
					let valueTwoReserved = '';

					// const countHoursMonthFree = totalFree/(60*60);
					const countShowsMonthFree = totalFree/duration;
					// const avgShowsInHourFree = Math.ceil(countShowsMonthFree/countHoursMonthFree);
					const avgShowsInHourFree = Math.ceil(countShowsMonthFree/(24*countDay));
					if(countShowsMonthFree) valueTwoFree = numeral(countShowsMonthFree).format('0a');
					// console.log({countHoursMonthFree, countShowsMonthFree, avgShowsInHourFree});

					// const countHoursMonthReserved = totalReserved/(60*60);
					const countShowsMonthReserved = totalReserved/duration;
					// const avgShowsInHourReserved = Math.ceil(countShowsMonthReserved/countHoursMonthReserved);
					const avgShowsInHourReserved = Math.ceil(countShowsMonthReserved/(24*countDay));
					if(countShowsMonthReserved) valueTwoReserved = numeral(countShowsMonthReserved).format('0a');

					data = {
						chunks: [
							{
								status: 'free',
								// valueOne: !!countHoursMonthFree? avgShowsInHourFree: 0,
								valueOne: avgShowsInHourFree,
								valueTwo: valueTwoFree,
							},
							{
								status: 'reserved',
								// valueOne: !!countHoursMonthReserved? avgShowsInHourReserved: 0,
								valueOne: avgShowsInHourReserved,
								valueTwo: valueTwoReserved,
							},
						]
					};
				}
				else if(monthStatusSet.has('mixed')){
					// const countHoursMonth = (totalFree + totalReserved)/(60*60);
					const countShowsMonth = (totalFree + totalReserved)/duration;
					// const avgShowsInHour = Math.ceil(countShowsMonth/countHoursMonth);
					const avgShowsInHour = Math.ceil(countShowsMonth/(24*countDay));
					if(countShowsMonth) valueTwo = numeral(countShowsMonth).format('0a');

					data = {
						status: 'mixed',
						valueOne: avgShowsInHour,
						valueTwo
					};
				}
				else if(monthStatusSet.has('disabled')){
					data = {
						status: 'disabled',
					};
				}
				else if(monthStatusSet.has('busy')){
					data = {
						status: 'busy',
					};
				}
			} else {
				if(
					((monthStatusSet.has('busy') || monthStatusSet.has('disabled') || monthStatusSet.has('mixed'))
					&& (monthStatusSet.has('free') || monthStatusSet.has('reserved') || monthStatusSet.has('free-reserved')))
					|| (monthStatusSet.has('busy') || monthStatusSet.has('mixed'))
				){

					// const countHoursMonth = (totalFree + totalReserved)/(60*60);
					const countShowsMonth = (totalFree + totalReserved)/duration;
					// const avgShowsInHour = Math.ceil(countShowsMonth/countHoursMonth);
					const avgShowsInHour = Math.ceil(countShowsMonth/(24*countDay));
					if(countShowsMonth) valueTwo = numeral(countShowsMonth).format('0a');

					data = {
						status: 'mixed',
						valueOne: avgShowsInHour,
						valueTwo
					};
				}
				else if(monthStatusSet.has('free') || monthStatusSet.has('reserved')){
					// const countHoursMonth = totalReserved/(60*60);
					const countShowsMonth = totalReserved/duration;
					// const avgShowsInHour = Math.ceil(countShowsMonth/countHoursMonth);
					const avgShowsInHour = Math.ceil(countShowsMonth/(24*countDay));
					if(countShowsMonth) valueTwo = numeral(countShowsMonth).format('0a');

					data = {
						status: 'reserved',
						valueOne: avgShowsInHour,
						valueTwo,
					};
				}
			}

			occupancy[yearMonth] = {
				...data,
				added,
			};
		}

		console.log({ occupancy });
		return occupancy;
	}
}

export default toNative(EmployInfoDigitalV2);