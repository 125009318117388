import { TEmployItem } from "../stores/types/types-structure";

type TDigitalParams = {
	monthData: Record<string, TEmployItem>,
	frequency?: number,
	duration?: number
}
type TStaticParams = {
	monthData: Record<string, TEmployItem>,
}

class Occupancy {
	getDigitalMonthCalendar(
		{ duration, frequency, monthData }: TDigitalParams,
		call?: (data: TEmployItem & { dayDate: string })=>void ): Record<string, string> {

		const calendar = {};
		const personTime = duration * frequency * 24;

		for(const [dayDate, item] of Object.entries(monthData)){
			const { free, reserved, busy, unavailable, status } = item;
			const totalTime = free + reserved;
			let statusDay = 'free';

			if(call) call({...item, dayDate});

			if(status=='dismounted' || unavailable) {
				// демонтировано, не работает
				statusDay = 'disabled';
			}
			else if(status=='busy') {
				// недоступно красный
				statusDay = 'busy';
			}
			else if(busy && (free || reserved) && personTime > totalTime){
				// Частично занято - розовый
				statusDay = 'mixed';
			}
			else if (free && (free >= personTime)) {
				// зеленый
				statusDay = 'free';
			}
			else if (free && (free < personTime) && totalTime >= personTime) {
				// двойная зеленый/желтый
				statusDay = 'free-reserved';
			}
			else if (reserved && (reserved >= personTime)) {
				// желтый
				statusDay = 'reserved';
			}
			else {
				// красный
				statusDay = 'busy';
			}

			calendar[dayDate] = statusDay;
		}

		return calendar;
	}

	getStaticMonthCalendar(
		{ monthData }: TStaticParams,
		call?: (data: TEmployItem & { dayDate:string })=>void ): Record<string, string> {

		const calendar = {};

		for(const [dayDate, item] of Object.entries(monthData)){
			const { free, reserved, busy, unavailable, status } = item;
			let statusDay = 'free';

			if(call) call({...item, dayDate});

			if(status=='dismounted' || unavailable) {
				// демонтировано, не работает
				statusDay = 'disabled';
			}
			else if(status=='busy') {
				// недоступно красный
				statusDay = 'busy';
			}
			else if(status=='mixed'
			// || (busy && (free || reserved))
			){
				// Частично занято - розовый
				statusDay = 'mixed';
			}
			else if (status=='free') {
				// зеленый
				statusDay = 'free';
			}
			else if (free && reserved) {
				// двойная зеленый/желтый
				statusDay = 'free-reserved';
			}
			else if (status=='reserved') {
				// желтый
				statusDay = 'reserved';
			}
			else {
				// красный
				statusDay = 'busy';
			}

			calendar[dayDate] = statusDay;
		}

		return calendar;
	}
}

export default new Occupancy();