import dayjs from 'dayjs';
import helper from '@/app/utils/helper';

export default {
	computed: {
		date(){
			const { notification } = this.data;
			let date = notification?.notificationTime;
			if(!date) date = new Date();

			let curFormatDate = dayjs(date).format('YYYY-MM-DD');
			let todayFormat = dayjs().format('YYYY-MM-DD');

			// console.log({curFormatDate, todayFormat, date, testDate: dayjs().toISOString()});
			if(todayFormat==curFormatDate){
				// const countHours = dayjs().diff(dayjs(date), 'hour', true);
				// if(countHours>6) return dayjs(date).format('HH:mm');
				// else {
				// 	if(countHours < 1) return 'менее часа назад';
				// 	else if(countHours >=1 && countHours < 2) return '1 час назад';
				// 	else if(countHours >=2 && countHours < 5) return `${Math.floor(countHours)} часа назад`;
				// 	else if(Math.floor(countHours) == 5) return `${Math.floor(countHours)} часов назад`;
				// }
				// return helper.dateTZ(dayjs(date)).format('HH:mm');
				return dayjs(date).format('HH:mm');
			}
			else{
				// return helper.dateTZ(dayjs(date)).format('DD.MM.YYYY');
				return dayjs(date).format('DD.MM.YYYY');
			}
		}
	},
	methods: {
		goToTargetProgram(){
			const { id } = this.data?.notification?.targetProgram;
			const { origin } = window.location;

			if(id) window.open(`${origin}/target-programs/${id}`, '_blank');
		},
	},
}