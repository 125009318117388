import { defineComponent } from 'vue';
import { mapState, mapActions, mapWritableState } from 'pinia';
import { useRootStore } from '@/app/stores/root-store';

import helper from '@/app/utils/helper';

import { ElContainer } from 'element-plus';
import BsHeader from '@/app/layouts/parts/headers/bs-header/BsHeader.vue';
import BsAside from '@/app/layouts/parts/bs-aside/BsAside.vue';
import BsMain from '@/app/layouts/parts/bs-main/BsMain.vue';

export default defineComponent({
	name: 'default-layout',
	components: {
		ElContainer,
		BsHeader, BsAside, BsMain,
	},
	computed:{
		...mapState(useRootStore, ['pageData']),

		headTitle(){
			let title = this.$route?.meta?.title ?? '';

			if(this.pageData?.title) title = this.pageData.title;
			return helper.upFirstLetter(title);
		}
	},
	watch:{
		headTitle: {
			handler(title){
				if(title) document.title = `ERP Outplan - ${title}`;
				else document.title = 'ERP Outplan';
			},
			immediate: true,
		},
	},
});