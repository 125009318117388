import { Component, Vue, toNative, Prop, Watch, } from 'vue-facing-decorator';
import dayjs from 'dayjs';

import helper from '@/app/utils/helper';

import { TEmployPeriod, THoverList } from './types/types-employ-info';

@Component({
	emits: ['update:checkMonthDate'],
})
export class BaseEmployInfo extends Vue {
	@Prop({ type: Object }) data!: object;
	@Prop({ type: Object, default: ()=>({}) }) period!: TEmployPeriod;
	@Prop sideData!: object;
	@Prop({ type: Boolean }) isDblMode!: boolean;
	@Prop({ type: Boolean }) isShowCalendar!: boolean;

	selectedMonth: string = '';
	monthElementData = {};
	hoverList:THoverList = [];

	get occupancy(){ return {} }
	get compPeriod() {
		let { from, to } = this.period ?? {};
		const year = dayjs().year();
		const month = dayjs().month();
		let format = { from: '', to: '' };

		if (!from) from = dayjs({ year, month, day: 1 }).format('YYYY-MM-DD');
		if (!to) to = dayjs(from).add(11, 'month').format('YYYY-MM-DD');
		format = {
			// from: dayjs(from).format('DD.MM'),
			// to: dayjs(to).format('DD.MM'),
			from: dayjs(from).format('D'),
			to: dayjs(to).format('D'),
		};

		return { from, to, format };
	}
	get isWithinOneMonth(){
		const { from, to } = this.compPeriod;
		const monthFrom = dayjs(from).format('MM');
		const monthTo = dayjs(to).format('MM');

		return monthFrom == monthTo;
	}
	get monthList(){
		const { from } = this.compPeriod;
		const year = dayjs(from).year();
		const month = dayjs(from).month();
		const monthList = [];

		for(let i=0; i<12; i++){
			const date = dayjs({ year, month: ( month + i ), day: 1 });
			let position = null;
			if(this.monthElementData[date.format('YYYY-MM')]) position = this.monthElementData[date.format('YYYY-MM')];

			monthList.push({
				date: date.format('YYYY-MM'),
				title: date.format('MMM').replace('.', ''),
				position,
			});
		}
		// console.log(monthList);
		return monthList;
	}
	get periodPosition() {
		const { from, to } = this.compPeriod;

		const keyFrom = dayjs(from).format('YYYY-MM');
		let keyTo = dayjs(to).format('YYYY-MM');
		const itemFrom = this.monthList.find(item => item.date == keyFrom);
		let itemTo = this.monthList.find(item => item.date == keyTo);

		// console.log(dayjs(to).diff(from, 'month'));
		if (dayjs(to).diff(from, 'month') > 11) {
			itemTo = this.monthList.at(-1);
		}
		if(keyFrom == keyTo){
			keyTo = dayjs(to).add(1,'months').format('YYYY-MM');
			itemTo = this.monthList.find(item=>item.date==keyTo);
			// console.log({keyTo, itemTo});
		}
		if (!itemFrom?.position || !itemTo?.position) return;

		if(this.isDblMode){
			const fromPos = itemFrom.position;
			const toPos = itemTo.position;
			const isDoubleEdge = fromPos.offsetTop == toPos.offsetTop;

			if(isDoubleEdge){
				let left = itemFrom.position.offsetLeft + itemFrom.position.offsetWidth / 5;
				let width = itemTo.position.offsetLeft - itemFrom.position.offsetLeft + (itemFrom.position.offsetWidth * 0.55);

				if(this.isWithinOneMonth){
					left = itemFrom.position.offsetLeft + itemFrom.position.offsetWidth / 5;
					width = (itemTo.position.offsetLeft - itemFrom.position.offsetLeft) * 0.55;
				}

				if(fromPos.offsetTop == 0) return { one: { left, width } };
				else return { two: { left, width } };
			} else {
				return {
					one: {
						left: fromPos.offsetLeft + fromPos.offsetWidth / 5,
						width: 6*fromPos.offsetWidth - fromPos.offsetLeft
					},
					two: {
						left: 2,
						width: (toPos.index+1 - 6)*toPos.offsetWidth - toPos.offsetWidth / 5
					}
				};
			}

		} else {
			let left = itemFrom.position.offsetLeft + itemFrom.position.offsetWidth / 2;
			let width = itemTo.position.offsetLeft - itemFrom.position.offsetLeft;

			if(this.isWithinOneMonth){
				// left = itemFrom.position.offsetLeft + itemFrom.position.offsetWidth / 8;
				// width = width * 0.7;
				left = itemFrom.position.offsetLeft + itemFrom.position.offsetWidth / 5;
				width = width * 0.55;
			}

			// console.log(left, width, itemFrom, itemTo);
			return { left, width };
		}
	}
	get hoverMap(){
		const map = {};
		for(const item of this.hoverList){
			map[item.date] = item.isHover;
		}
		return map;
	}

	isCheckPeriod(date: string) {
		const { selectedPeriods } = this.sideData as { selectedPeriods } || {};
		const monthDate = dayjs(date).format('YYYY-MM');

		if(selectedPeriods?.[monthDate]?.length) return true;
		return false;
	}

	calcMonthElementPosition(){
		const monthItem = this.$refs?.monthItem as HTMLDivElement[] ?? [];
		const { from, to } = this.compPeriod;
		const map = {};
		const monthList = monthItem.sort((elA, elB)=>{
			const yearMonthA = elA.dataset.monthDate;
			const yearMonthB = elB.dataset.monthDate;
			const dateA = dayjs(yearMonthA).toDate();
			const dateB = dayjs(yearMonthB).toDate();

			return dateA.getTime() - dateB.getTime();
		});

		for( let [i, el] of Object.entries(monthList)){
			const index = Number(i);
			const key = dayjs(from).add(index, 'month').format('YYYY-MM');
			// console.log({el});
			const { offsetLeft, offsetWidth, offsetTop } = el as HTMLDivElement;
			map[key] = { index, offsetLeft, offsetWidth, offsetTop };
		}
		// console.log(map);
		this.monthElementData = map;
	}

	getMonthItemData(monthData) {
		if (!this.occupancy) return;

		const data = this.occupancy?.[monthData.date];
		if (!data) return;

		return data;
	}

	getTypeItem(monthData) {
		const data = this.getMonthItemData(monthData);

		if (data?.chunks) return 'multi';
		else return 'single';
	}

	setHoverMonthItem(yearMonth: string, isHover: boolean){
		const findItem = this.hoverList.find(item=>item.date==yearMonth);
		if(findItem) findItem.isHover = isHover;
	}

	clickMonth(date: string){
		if(!this.isShowCalendar && this.selectedMonth==date) this.selectedMonth = '';
		else this.selectedMonth = date;

		this.$emit('update:checkMonthDate', this.selectedMonth);
	}

	@Watch('monthList', { immediate: true, })
	monthListWatcher(monthList){
		this.hoverList = monthList.map(item=>{
			return {
				date: item.date,
				isHover: false,
			};
		});
	}

	@Watch('period')
	async periodWatcher(){
		// console.log('watch-period');
		await this.$nextTick();
		helper.pause(100, ()=>{
			this.calcMonthElementPosition();
		});
	}

	@Watch('checkMonthDate')
	checkMonthDateWatcher(date: string){
		this.selectedMonth = date;
	}

	async mounted() {
		await this.$nextTick();
		helper.pause(100, ()=>{
			this.calcMonthElementPosition();
		});
	}
	created() {
		if(this.isShowCalendar){
			const { from, to } = this.compPeriod;
			this.selectedMonth = dayjs(from).format('YYYY-MM');
		}
		this.$emit('update:checkMonthDate', this.selectedMonth);
	}
}