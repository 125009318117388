import { statusColor, typeSide } from "./marker-colors";

export default {
	props: {
		sides: {
			type: Array,
			default: [],
		},
		angle: {
			type: Number,
			default: 0,
		},
		statusSidesMap: Map,
	},
	computed: {
		styleMarker(){
			const styleList = [];

			styleList.push(`--rotate-label: ${this.angle }deg;`);

			for(const [index, item] of Object.entries(this.sides)){
				const { id, inTargetProgram, countSurfaces } = item ?? {};
				const sideStatus = this.statusSidesMap.get(id);
				let countSurf = 0;
				// console.log(sideStatus);
				// occupancy
				if(item?.isDigital || item?.is_digital) styleList.push(`--color-side-${+index+1}: ${typeSide.digital}`);
				// else if(countSurfaces){
				// 	for(const [key, value] of Object.entries(countSurfaces)){
				// 		countSurf += value;
				// 	}
				// }

				if(sideStatus){
					const { free, reserve, busy, unavailable, partSold } = sideStatus;

					// if(unavailable) styleList.push(`--color-status-${+index+1}: ${statusColor.partSold}`);
					if(false){  }
					// else if(!(item?.isDigital || item?.is_digital) && countSurf==1){
					// 	styleList.push(`--color-status-${+index+1}: ${statusColor.confirmed}`);
					// 	if(inTargetProgram) styleList.push(`--color-crown-${+index+1}: ${statusColor.confirmed}`);
					// }
					else if(partSold){
						styleList.push(`--color-status-${+index+1}: ${statusColor.partSold}`);
						if(inTargetProgram) styleList.push(`--color-crown-${+index+1}: ${statusColor.partSold}`);
					}
					else if((free && reserve) || (free && busy) || (reserve && busy)){
						styleList.push(`--color-status-${+index+1}: ${statusColor.partSold}`);
						if(inTargetProgram) styleList.push(`--color-crown-${+index+1}: ${statusColor.partSold}`);
					}
					else if(free){
						styleList.push(`--color-status-${+index+1}: ${statusColor.free}`);
						if(inTargetProgram) styleList.push(`--color-crown-${+index+1}: ${statusColor.free}`);
					}
					else if(reserve){
						styleList.push(`--color-status-${+index+1}: ${statusColor.reserved}`);
						if(inTargetProgram) styleList.push(`--color-crown-${+index+1}: ${statusColor.reserved}`);
					}
					else if(busy){
						styleList.push(`--color-status-${+index+1}: ${statusColor.busy}`);
						if(inTargetProgram) styleList.push(`--color-crown-${+index+1}: ${statusColor.busy}`);
					}
				}
			}

			return styleList.join('; ');
		},

	},
}