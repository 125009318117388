import { toRaw, defineComponent } from 'vue';
import { useRootStore } from '@/app/stores/root-store';
import { useMapDesignStore } from "@/app/stores/structures/map-design-store";
import { useDesignFilterStore } from '@/app/stores/structures/design-filter-store';

import helper from '@/app/utils/helper';

import { ElPopover } from 'element-plus';

import MarkerTypeOne from "./types/marker-type-one/MarkerTypeOne.vue";
import MarkerTypeTwo from "./types/marker-type-two/MarkerTypeTwo.vue";
import MarkerTypeThree from "./types/marker-type-three/MarkerTypeThree.vue";
import MarkerTypeIndor from "./types/marker-type-indor/MarkerTypeIndor.vue";
import MarkerTypeAngular from './types/marker-type-angular/MarkerTypeAngular.vue';

import DesignMarkerPopup from '@/app/components/map/design-marker-popup/DesignMarkerPopup.vue';

export default defineComponent({
	name: 'MapDesignMarker2',
	components: {
		ElPopover,
		DesignMarkerPopup,

		MarkerTypeOne, MarkerTypeTwo, MarkerTypeThree,
		MarkerTypeIndor, MarkerTypeAngular,
	},
	props: {
		data: {
			type: Object,
			default: ()=>({}),
		}
	},
	data() {
		return {
			isShow: false,
			isHover: false,
			isOpenPopup: false,
			isHoverPopup: false,
			popupData: null,
			timerInterval: null,
		};
	},
	computed: {
		detailDesignData(){
			const { detailDesignData } = useMapDesignStore();
			return detailDesignData;
		},

		isMarkerShow(){
			const { checkMarkerInTarget } = useMapDesignStore();
			const { targetProgram } = useDesignFilterStore();

			if(checkMarkerInTarget && targetProgram?.value) return this.inTargetProg;
			return true;
		},

		markerData(){
			return this.data?.markerData ?? {};
		},

		structData(){
			return this.markerData?.structData || {};
		},
		sides(){
			let sides = helper.clone(this.structData?.sides || []);
			sides.sort((a,b)=>(a.angle - b.angle));
			// console.log(sides);
			return sides;
		},
		statusSidesMap(){
			const { statusSidesMap } = this.markerData?.statusInfo ?? {};
			if(!statusSidesMap) return new Map();

			return statusSidesMap;
		},
		angle(){
			let index = 0;
			let angle = 0;

			while(index < this.countSides){
				let findSide = this.sides[index];
				if(findSide){
					const sideAngle = findSide?.angle;
					if(!(sideAngle === '' || sideAngle === undefined || sideAngle === null)){
						angle = sideAngle;
						break;
					}
				}
				index++;
			}
			if(index > 0){
				const dtAngel = 360 / this.countSides;
				angle = angle - dtAngel*index;
			}

			return angle;
		},
		countSides(){
			let { countSides } = this.structData ?? {};
			if(!countSides) countSides = this.structData?.sides?.length;

			return countSides ?? 1;
		},
		inTargetProg(){
			return !!this.sides.find(item=>item.inTargetProgram);
		},
		isSelected(){
			return this.markerData?.id && (this.detailDesignData?.id == this.markerData?.id);
		},
		styleMarker(){
			const styleList = [];

			styleList.push(`--rotate-marker: ${this.angle * -1}deg;`);

			return styleList.join('; ');
		},
		sizeMarker(){
			const { categoryType } = this.structData;
			const type = categoryType?.toLowerCase();

			if(type == 'крупные') return 'large';
			else if(type == 'малые') return 'small';
			else if(type == 'индор') return 'indor';

			return 0;
		},
		isAngularType(){
			// countSides
			if(this.countSides != 2) return false;

			const { geometry } = this.structData;
			// const side = this.sides?.find(item=>item?.direction?.toLocaleLowerCase()=='a');
			if( geometry?.toLocaleLowerCase() === 'галка' ) return true;
			return false;
		},

		// isDigital(){
		// 	return !!this.sides.find(item=>item?.isDigital || item?.is_digital);
		// },
	},
	methods: {
		async openStructure(){
			const { isShowSecondAside, setShowSecondAside } = useRootStore();
			const { getStructure } = useMapDesignStore();

			console.log(this.markerData.id, this.markerData);

			await getStructure({ id: this.markerData.id, noDataMap: true });
			if(!isShowSecondAside) setShowSecondAside(true);
		},

		mouseEnter(){
			this.isHover = true;
		},
		mouseLeave(){
			this.isHover = false;
		},
		async openPopup(){
			console.log('markerData',{
				structData: this.structData,
				sides: this.sides,
				statusInfo: this.markerData?.statusInfo,
			});
			const { getPopupStructure } = useMapDesignStore();

			const { id } = this.structData;
			const res = await getPopupStructure(id);
			if(!res?.data) return;

			console.log('openPopup', res);
			this.popupData = res.data;
			$$(window).trigger('open:markerPopup', { code: this.markerData.code });
			this.isOpenPopup = true;
		},

		mouseEnterPopup(){
			// this.isHoverPopup = true;
		},
		mouseLeavePopup(){
			// this.isHoverPopup = false;
		},
		closePopup(){
			this.isOpenPopup = false;
			this.isHoverPopup = false;
		}
	},
	watch: {
		isHover(is){
			if(is) helper.debounce({
				keyTimer: 'hover-enter-map-marker',
				duration: 500,
				action: ()=>{
					if(!this.isHover) return;
					if(!this.isOpenPopup) this.openPopup();
				},
			});
			// else helper.debounce({
			// 	keyTimer: 'hover-leave-map-marker',
			// 	duration: 600,
			// 	action: ()=>{
			// 		if(!this.isHover && !this.isHoverPopup) this.isOpenPopup = false;
			// 	},
			// });
		},
	},
	created() {
		// console.log(this.structData);
	},
	mounted(){
		const { placemark } = this.data;

		placemark.events.add('click', (e)=>{
			this.openStructure();
			// this.isOpenPopup = true;
		});
		placemark.events.add('mouseenter', (e)=>{
			this.mouseEnter();
		});
		placemark.events.add('mouseleave', (e)=>{
			this.mouseLeave();
		});

		$$(window).on(`update:map.${this.markerData.code}`, async ()=>{
			this.closePopup();
		});
		$$(window).on(`open:markerPopup.${this.markerData.code}`, (event, data)=>{
			if(data.code == this.markerData.code) return;

			this.closePopup();
		});
	},
	unmounted() {
		const { placemark } = this.data;
		placemark.events.remove('click', (e)=>{
			this.openStructure();
		});
		placemark.events.remove('mouseenter', (e)=>{
			this.mouseEnter();
		});
		placemark.events.remove('mouseleave', (e)=>{
			this.mouseLeave();
		});

		$$(window).off(`update:map.${this.markerData.code}`);
		$$(window).off(`open:markerPopup.${this.markerData.code}`);

		this.closePopup();

		if(this.timerInterval) clearInterval(this.timerInterval);
	},
});