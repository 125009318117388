import { TUser } from './types/types-user';

import { defineStore } from 'pinia';

import router from '@/app/router';
import api from '@/app/utils/service-api';
import errorService from '@/app/utils/error-service';

import { ElMessage } from 'element-plus';

export const useUserStore = defineStore('userStore', {
	state() {
		return {
			returnRoute: null,
			user: {} as TUser,
		};
	},
	getters: {
		signed(state){
			const locEmail = window.localStorage.getItem('auth-user-email');
			const { id, email } = state.user ?? {};

			return !!id && email==locEmail;
		},
		permissions(state){
			return state.user?.permissions ?? [];
		},
		permissionsSet(){
			const set = new Set();
			for(const item of this.permissions){
				set.add(item);
			}
			return set;
		},
		roles(state){
			return state.user?.roles ?? [];
		},

	},
	actions: {
		setReturnRouteName(routeData) {
			this.returnRoute = routeData;
		},

		async getUser() {
			try {
				const user = await api.getData({ model: 'user' });
				console.log('user', user);

				const jsonUserNewData = JSON.stringify(user);
				const jsonUserOldData = JSON.stringify(this.user);
				if(user && (jsonUserNewData!==jsonUserOldData)) this.user = user;

				return user;
			} catch (error) {
				console.error(error);
			}

			return;
		},

		async signIn(email: string, password: string) {
			try {
				const user: TUser = await api.postData({
					model: 'sign-in',
					data: { email, password },
				});

				if(user?.email){
					window.localStorage.setItem('auth-user-email', user.email);
				}

				if(user?.id){
					this.user = user;
					// console.log(this.returnRoute);
					if(this.returnRoute) router.push(this.returnRoute);
					else router.push({ name: 'structures' });
				}

				return user;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({ error });
			}

			return;
		},

		async logout(){
			try {
				const res = await api.postData({ model: 'logout' });

				if(res){
					this.user = {} as TUser;
					this.returnRoute = null;

					router.push({
						name: 'sign-in',
					});
				}
				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({ error });
			}

			return;
		},

		hasRoles(keyRole: string, isExact?: boolean){
			if(isExact){
				return this.roles.includes(keyRole);
			}

			return this.roles.some(item => item.includes(keyRole));
		}
	}
})