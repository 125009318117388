import { defineComponent } from 'vue';

import EmployInfoStatic from '@/app/components/map/map-design-info/employ-info/employ-info-static/EmployInfoStatic.vue';
import EmployInfoDigital from '@/app/components/map/map-design-info/employ-info/employ-info-digital/EmployInfoDigital.vue';

import EmployInfoStaticV2 from './employ-info-static-v2/EmployInfoStaticV2.vue';
import EmployInfoDigitalV2 from './employ-info-digital-v2/EmployInfoDigitalV2.vue';

export default defineComponent({
	name: 'employ-info',
	components: {
		// EmployInfoStatic, EmployInfoDigital,
		EmployInfoStaticV2, EmployInfoDigitalV2,
	},
	props: {
		type: {
			type: String, // 'static', 'digital'
			default: 'static',
		},
		period: Object,
		data: Object,
		sideData: Object,
		// checkMonthDate: String,
		isShowCalendar: Boolean,
		isDblMode: {
			type: Boolean,
			default: false,
		}
	},
	emits: ['update:checkMonthDate'],
	data() {
		return {
			selectedMonth: '',
		}
	},
	computed: {
		// ...mapState(useDesignFilterStore, ['placePeriodDate']),
		duration(){
			return Number(this.sideData.digitalDuration?.value);
		},
		frequency(){
			return Number(this.sideData.outFrequency?.value);
		},
	},
	methods: {
		changeMonth(date){
			this.selectedMonth = date;
			this.$emit('update:checkMonthDate', date);
		}
	},
	watch: {
		checkMonthDate(val){
			this.selectedMonth = val;
		},
		async sideData(data){
			// console.log(data);
			this.$emit('update:checkMonthDate', '');
			await this.$nextTick();
			// await helper.pause(10);
			this.$emit('update:checkMonthDate', this.selectedMonth);
		},
	},
	created(){

	}
});