import { defineComponent } from 'vue';
import BsBtn from '@/app/components/controls/buttons/bs-btn/BsBtn.vue';
import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';

export default defineComponent({
	name: 'side-select',
	components: {
		BsBtn, SvgIcon,
	},
	props: {
		modelValue: [String, Number],
		/**
		{
			value: id,
			label,
			inTargetProgram,
			isWorking: serviceStatus?.toLowerCase() === 'работает',
			light: !!lighted,
			isDigital: is_digital,
		}[]
		 */
		options: Array,
	},
	emits: ['update:modelValue', 'change'],
	computed: {
		value:{
			get(){
				return this.modelValue;
			},
			set(val){
				this.$emit('update:modelValue', val);
			}
		},
	},
	methods: {
		clickBtn(item){
			if(item.value == this.value) return;
			this.value = item.value;

			this.$emit('change', this.value);
		},
		getClassBtn(item){
			let classList = [];

			if(item?.light) classList.push('--is-light');
			if(this.value == item.value) classList.push('--is-active');
			if(item?.inTargetProgram) classList.push('--is-in-target-program');
			if(item?.isWorking !== undefined && !item?.isWorking) classList.push('--is-not-working');

			return classList.join(' ');
		}
	},
	watch:{
		// options(list){
		// 	console.log('options', list);
		// }
	},
	created() {
		// console.log('options', this.options);
	},
});