import { defineComponent } from 'vue';
import { useRootStore } from '@/app/stores/root-store';
import { useMapDesignStore } from "@/app/stores/structures/map-design-store";
import { useDesignFilterStore } from '@/app/stores/structures/design-filter-store';
import { useTargetCartStore } from '@/app/stores/target-programs/target-cart-store';

import helper from '@/app/utils/helper';

import { ElImage, ElMessageBox, ElMessage } from "element-plus";
import SvgIcon from "@/app/components/svg-icon/SvgIcon.vue";
import BsBtn from '@/app/components/controls/buttons/bs-btn/BsBtn.vue';
import BtnAction from '@/app/components/controls/buttons/btn-action/BtnAction.vue';
import SideSelect from '@/app/components/map/map-design-info/side-select/SideSelect.vue';

export default defineComponent({
	name: 'design-marker-popup',
	components: {
		ElImage, SvgIcon,
		BsBtn, BtnAction, SideSelect,
	},
	props: {
		data: {
			type: Object,
			default: ()=>({}),
		},
	},
	emits: ['closePopup'],
	data() {
		return {
			selectSide: '',
			isLoadingOpenStructure: false,
		}
	},
	computed:{
		defaultData(){
			const { address, gid, type } = this.data ?? {};
			return {
				address: address || '',
				gid: gid || '',
				structureType: type || '',
			}
		},
		sides(){
			return this.data?.sides ?? [];
		},
		sideSelectOptions(){
			const options = [];
			if(this.sides?.length) for( const item of this.sides ){
				const { id, direction, sideCode, lighted, isDigital, inTargetProgram, serviceStatus } = item;

				if(!serviceStatus?.toLowerCase()?.includes('демонт')) options.push({
					value: id,
					label: sideCode? sideCode : direction,
					light: lighted,
					isDigital,
					inTargetProgram,
					isWorking: serviceStatus?.toLowerCase() == 'работает',
				});
			}

			return options
				.sort((a,b)=>a.label.localeCompare(b.label));
		},
		sideData(){
			const side = this.sides.find(item=>item.id==this.selectSide) ?? {};
			const { type, price } = side;

			return {
				...side,
				priceMonth: price?.RUB ?? 0,
				mechanism: type ?? '',
			}
		},
		firstPhoto(){
			const { photoList } = this.sideData ?? {};
			if(!(photoList && photoList?.length)) return '';

			const photoItem = photoList[0] ?? {};
			const firstPhoto = photoItem?.['528'] ?? '';

			return firstPhoto;
		},
		photoGallery(){
			let gallery = [];
			const { photoList } = this.sideData ?? {};

			if(!(photoList && photoList?.length)) return gallery;
			gallery = photoList.map(item=>{
				return item?.['1920'] ?? '';
			});

			return gallery;
		},
		sideInCartList(){
			const { cartData } = useTargetCartStore();
			const { id } = this.sideData;
			const filterSides = cartData?.filter(item=>item.sideId==id) ?? [];
			const cartItemIds = filterSides.map(item=>item.cartItemId);

			return cartItemIds;
		},
	},
	methods: {
		getMoney: helper.getMoney,

		getTypeDesignIcon(name){
			const { getTypeDesignIcon } = useMapDesignStore();
			return getTypeDesignIcon(name);
		},
		getCartData(){
			const { getCartData } = useTargetCartStore();
			return getCartData();
		},

		getFieldValue(key){
			return Number( this.sideData?.[key] ?? 0 ) || '-';
		},
		async openStructure(){
			console.log(this.data.id, this.data);
			const { getStructure } = useMapDesignStore();
			const { isShowSecondAside, setShowSecondAside } = useRootStore();

			this.isLoadingOpenStructure = true;
			await getStructure({ id: this.data.id, noDataMap: true });
			this.isLoadingOpenStructure = false;

			if(!isShowSecondAside) setShowSecondAside(true);
		},

		async addTargetCart(){
			let data = {
				side: this.sideData.id,
				periods: [],
			};

			const designFilterStore = useDesignFilterStore();
			const { placePeriodDate, digitalVideoLength } = designFilterStore;
			const { from, to } = placePeriodDate;
			data.periods = [[from, to]];

			if(this.sideData?.isDigital){
				let duration = '';
				let showsInHour = 3600/this.sideData.block;

				if(digitalVideoLength.trim()) duration = Number(digitalVideoLength);
				if(designFilterStore.showsInHour.value) showsInHour = designFilterStore.showsInHour.value;

				if(!duration) duration = this.sideData.slot;

				data = {
					...data,
					duration, showsInHour,
				};
			}
			console.log('addTargetCart', data);

			const { addCartItem } = useTargetCartStore();
			const res = await addCartItem({ data });
			if(res?.data){
				ElMessage({
					type: 'success',
					message: 'РС успешно добавлена в Корзину',
				});
				this.getCartData();
			}
		},
		removeSideInCart(){
			ElMessageBox.confirm(null, null, {
				type: 'warning',
				title: 'Внимание!',
				message: 'Вы уверены, что хотите удалить все строки с этой РС из корзины?',
				customClass: 'bs-message-box',
			}).then(async () => {
				const { removeCartList } = useTargetCartStore();
				const res = await removeCartList(this.sideInCartList);
				if(res){
					ElMessage({
						type: 'success',
						message: 'РС успешно удалены из Корзины',
					});
					this.getCartData();
				}
			}).catch(() => { });
		}
	},
	created(){
		const selectedSide = this.sides.find(item => item.selected);

		if(selectedSide) this.selectSide = selectedSide.id;
		else this.selectSide = this.sideSelectOptions[0]?.value ?? '';
	}
});
