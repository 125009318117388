<template lang="pug">

svg.ico: use( :xlink:href="`/assets/img/sprite-map.${hash}.svg#sprite-separator`" )

</template>

<script>
export default {
	name: 'SeparatorIcon',
	data() {
		return {
			hash: __webpack_hash__
		}
	},
}
</script>