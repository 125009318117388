import { createRouter, createWebHistory } from "vue-router";
import { routes } from "./routes";
import { useUserStore } from "@/app/stores/user-store";

// const isDev = window.localStorage.getItem('dev');

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach(async (to) => {
	const auth = useUserStore();
	const { hostname } = window.location;

	const publicPages = ['sign-in'];
	const authRequired = !publicPages.includes(to.name);

	if(hostname.includes('localhost')){
		auth.getUser();
	}
	else {
		if(!auth.signed) {
			await auth.getUser();
		}

		if(auth.signed && to.name === 'sign-in') {
			return { name: 'structures' };
		}

		if(authRequired && !auth.signed) {
			// console.log({to});
			auth.setReturnRouteName(to);
			return { name: 'sign-in' };
		}
	}

	// доступ к странице прайсовых политик
	if(to?.name=='pricing-policies'){
		const { hasRoles } = auth;

		// permissions - нет доступа, если менеджер или роп
		if((hasRoles('MANAGER') || hasRoles('HEAD_OF_SALES'))){
			// console.info('permissions - MANAGER, HEAD_OF_SALES');
			return { name: 'structures' };
		}
	}

})

export default router;