import { defineStore } from 'pinia';
import { useDesignFilterStore } from './structures/design-filter-store';
import { useGridDesignStore } from './structures/grid-design-store';
import { useMapDesignStore } from './structures/map-design-store';
import { useFiltersStore } from './filters-store';

import api from '@/app/utils/service-api';
import helper from '@/app/utils/helper';
import errorService from '@/app/utils/error-service';

import { ElMessageBox } from 'element-plus';

type TProgDialog = {
	isOpen: boolean,
	data: TProgDialogData | null
}
type TProgDialogData = {
	isManualTargetCreate?: boolean,
	callAction?: (targetProgId: number)=>void
}

export const useDialogStore = defineStore('dialogStore', {
	state() {
		return {
			targetProgDialog: {
				isOpen: false,
				data: null,
			} as TProgDialog,

			addTprogConfirmDialog: {
				isOpen: false,
				data: null,
			},

			targetProgDialogData: {
				dealId: '',
				linkDealB24: '',

				ruleForm : {
					nameTargetProgram: '',
					// clientType: '',
					// clientCategory: '',
					// typePlacement: '',
					department: '',
				},

				agency: '',
				client: '',
				brand: '',
				// promo: '',
				legalPerson: '',
				rimPerson: '',
				install: '',
				addInstall: '',
				print: '',
				fastPrint: '',
				design: '',
				photoReport: '',
			},
			adapterTargetProgBack: [
				{
					type: 'input',
					name: 'Название адресной программы',
					key: 'nameTargetProgram',
					keyBack: 'name',
				},
				{
					type: 'select',
					name: 'Агентство',
					key: 'agency',
					keyBack: 'agency',
				},
				{
					type: 'select',
					name: 'Клиент',
					key: 'client',
					keyBack: 'customer',
				},
				// {
				// 	key: 'clientType',
				// 	keySubmit: 'customer_type',
				// 	keyBack: 'customer_type',
				// },
				// {
				// 	type: 'select',
				// 	name: 'Категория клиента',
				// 	key: 'clientCategory',
				// 	keyBack: 'customer_category',
				// },
				{
					type: 'select',
					name: 'Бренд',
					key: 'brand',
					keyBack: 'brand',
				},
				// {
				// 	type: 'select',
				// 	name: 'Тип размещения',
				// 	key: 'typePlacement',
				// 	keyBack: 'placement_type',
				// },
				{
					type: 'select',
					name: 'Подразделение',
					key: 'department',
					keyBack: 'department',
				},
				// {
				// 	type: 'select',
				// 	name: 'Акции',
				// 	key: 'promo',
				// 	keyBack: 'promo',
				// },
				{
					type: 'input',
					name: 'ID сделки',
					key: 'dealId',
					keyBack: 'deal',
				},
				{
					type: 'input',
					name: 'Ссылка на сделку в Битрикс24',
					key: 'linkDealB24',
					keyBack: 'birix_deal_url',
				},
				//
				{
					type: 'select',
					name: 'Юр. лицо клиента',
					key: 'legalPerson',
					keyBack: 'customer_legal_entity',
				},
				{
					type: 'select',
					name: 'Юр. лицо МГ РИМ',
					key: 'rimPerson',
					keyBack: 'rim_legal_entity',
				},
				{
					type: 'select',
					name: 'Монтаж',
					key: 'install',
					keyBack: 'installer',
				},
				// {
				// 	key: 'addInstall',
				// 	keySubmit: '',
				// 	keyBack: '',
				// },
				{
					type: 'select',
					name: 'Печать',
					key: 'print',
					keyBack: 'printer',
				},
				// {
				// 	key: 'fastPrint',
				// 	keySubmit: '',
				// 	keyBack: '',
				// },
				{
					type: 'select',
					name: 'Дизайн',
					key: 'design',
					keyBack: 'designer',
				},
				{
					type: 'select',
					name: 'Фотоотчет',
					key: 'photoReport',
					keyBack: 'photo_reporter',
				},
			],
		};
	},

	getters: { },
	actions: {
		openTargetProgDialog(data: TProgDialogData){
			this.targetProgDialog = {
				isOpen: true,
				data,
			};
		},
		openAddTprogConfirmDialog(data){
			this.addTprogConfirmDialog = {
				isOpen: true,
				data,
			};
		},

		buildTargetProgSubmitData(){
			const data = {};

			for( let item of this.adapterTargetProgBack ){
				const { type, key, keyBack } = item;
				let value = '';

				if([ 'nameTargetProgram', 'department',
					// 'typePlacement', 'clientCategory',
				].includes(key)) value = this.targetProgDialogData.ruleForm?.[key];
				else if(['dealId', 'linkDealB24'].includes(key)) value = this.targetProgDialogData?.[key];
				else value = this.targetProgDialogData?.[key];

				if(value) data[keyBack] = value;
			}

			return data;
		},

		async createTargetProgram(){
			const filterStore = useFiltersStore();
			const designFilterStore = useDesignFilterStore();
			const { targetProgram } = designFilterStore;
			const { getOptions } = filterStore;
			const data = this.buildTargetProgSubmitData();
			console.log(data);

			try {
				let res = await api.postData({ model: 'target-program/create', data });
				console.log('createTargetProgram', res);

				if(res?.data){
					const { id, name } = res.data;
					const options = getOptions('targetProgram');
					const findOptTargetProg = options.find(item=>item.value==id);

					if(!findOptTargetProg){
						options.push({
							value: id,
							label: name
						});
						targetProgram.value = id;
					}

					return res.data;
				}
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({ error });
			}

			return;
		},

		/** deprecated
		 * Добавление стороны в АП
		 * @param {{
		 * target_program_id: Number,
		 * orders: {
		 * 	side_id: Number,
		 * 	periods: [from: String, to: String][],
		 * 	duration: Number,
		 * 	frequency: String,
		 * }[],
		 * }} data
		*/
		async addTargetProgram(data={}){
			const mapDesignStore = useMapDesignStore();
			const { buildDetailDesignData, buildMapData ,detailDesignData, markerList } = mapDesignStore;

			const gridStore = useGridDesignStore();
			const { buildGridTableData, gridTableData } = gridStore;

			const designFilterStore = useDesignFilterStore();
			const { getSubmitFilterData } = designFilterStore;
			const filters = getSubmitFilterData();

			const { from_to } = filters ?? {} as typeof filters & { from_to:string };
			const [from, to] = from_to?.split(';') ?? [];

			if(!(from && to) || from=='Invalid Date' || to=='Invalid Date'){
				ElMessageBox.alert(null, null, {
					type: 'warning',
					title: 'Внимание!',
					message: 'РС не добавлена! Проверьте правильность заполнения полей фильтра "Период размещения"',
					customClass: 'bs-message-box',
				}).then(() => { }).catch(() => { });

				return;
			}
			console.log('addTargetProgram', filters);

			try {
				const res = await api.postData({
					model: 'order/create',
					data: {
						...data,
						filters,
					}
				});
				const { structure, point } = res ?? {};
				console.log('addTargetProgram', res);

				if(structure){
					const structureId = structure?.id;
					const findIndex = gridTableData.findIndex(item=>item.id==structureId);

					// Обновление данных карточки РК реестра
					if( structureId && detailDesignData?.id && structureId == detailDesignData?.id ){
						const oldSideValue = detailDesignData?.sideSelectData?.value;
						const newDetailDesignData = buildDetailDesignData(structure);
						const { sideSelectData } = newDetailDesignData;

						if(oldSideValue && oldSideValue != sideSelectData.value) sideSelectData.value = oldSideValue; // установка ранее выбранной стороны
						if(newDetailDesignData) mapDesignStore.detailDesignData = newDetailDesignData;
					}

					// Обновление данных строки датагрида реестра
					if(gridTableData?.length && findIndex !== undefined && findIndex>=0){
						const oldSideValue = gridTableData?.[findIndex]?.sideSelectData?.value;
						const newRow = buildGridTableData([structure])[0];

						if(oldSideValue && oldSideValue != newRow.sideSelectData.value ) newRow.sideSelectData.value = oldSideValue;
						gridTableData[findIndex] = newRow;
					}
				}

				// Обновление маркера на карте
				if(point){
					const markerId = point?.id;
					const findIndex = markerList.findIndex(item=>item.id==markerId);
					const mapData = await buildMapData([point]);
					const newMarker = mapData.markerList[0];
					console.log({newMarker});

					if(markerList?.length && findIndex !== undefined && findIndex>=0){
						const marker = markerList[findIndex];
						marker.statusInfo = newMarker.statusInfo;
					}
				}

				// сброс выбранных РС в таблице
				$$(window).trigger('clear-table-selection');
				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({ error });
			}

			return;
		}

	},
});