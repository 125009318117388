import { defineStore } from 'pinia';
import { useRootStore } from '../root-store';
import { useMapDesignStore } from '../structures/map-design-store';
import { useGridDesignStore } from '../structures/grid-design-store';

import dayjs from 'dayjs';

import api from '@/app/utils/service-api';
import helper from '@/app/utils/helper';
import errorService from '@/app/utils/error-service';

import { ICartItem, ITargetTabData } from '../types/types-target-programs';
import { TPeriod } from '@/types/types-global';

export const useTargetCartStore = defineStore('targetCartStore', {
	state() {
		return {
			targetProgramId: null,
			cartData: null as ICartItem[] | null,
			targetTabData: null as ITargetTabData | null,
		};
	},

	getters: {
		cartInTpSet(){
			let sideCartList = [];
			let sideTpList = [];
			if(this.cartData && Array.isArray(this.cartData)) sideCartList = this.cartData.map(item=>item.sideId);
			if(this.targetTabData && Array.isArray(this.targetTabData)) sideTpList = this.targetTabData?.sides?.map(item=>item.sideId);

			const filterList = sideCartList.filter(item=>sideTpList.includes(item));
			console.log({filterList});

			return new Set(filterList);
		}
	},
	actions: {
		async getCartData(){
			try {
				const res = await api.postData({
					model: 'target-program/cart/items',
				});
				console.log('getCartData', res);
				if(res?.data && Array.isArray(res.data)) this.cartData = res.data;

				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({ error });
			}

			return;
		},
		async addCartItem({ data }){
			try {
				const res = await api.postData({
					model: 'target-program/cart/items/add-item',
					data,
				});
				console.log('addCartItem', res);

				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({ error });
			}
			return;
		},
		async addGidToCart({gids, periods}:{gids: string[], periods: TPeriod[]}): Promise<{ data: ICartItem[] }> {
			try {
				const res = await api.postData({
					model: 'target-program/cart/items/add-items-by-gids',
					data: { gids, periods },
				});
				console.log('addGidToCart', res);

				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({ error });
			}
			return;
		},
		async updateCartItem({ cartItemId, data={} }){
			// const { side, periods } = data;
			if(!cartItemId) return;

			try {
				const res = await api.postData({
					model: `target-program/cart/items/${cartItemId}/update`,
					data,
				});
				console.log('updateCartItem', res);
				if(res?.data){
					const { cartItemId } = res.data;
					const index = this.cartData.findIndex((item) => item.cartItemId == cartItemId);
					this.cartData[index] = res.data;
				}

				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({ error });
			}

			return;
		},
		async removeCartItem(cartItemId){
			if(!cartItemId) return;

			try {
				const res = await api.postData({
					model: `target-program/cart/items/${cartItemId}/remove`,
				});
				console.log('removeCartItem', res);

				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({ error });
			}
			return;
		},
		async removeCartList(cartItemIds=[]){
			if(!cartItemIds.length) return;

			try {
				const res = await api.postData({
					model: `target-program/cart/items/remove-items`,
					data: { cartItemIds },
				});
				console.log('removeCartList', res);

				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({ error });
			}
			return;
		},

		/**
		 * Добавление нескольких элементов в корзину
		 * @param {{
		 *	targetProgramId: number,
		 *	orders: { 			// - required
		 *		side: number, 	// - required
		 *		duration: number, // для диджиталки
		 *		showsInHour: number, // для диджиталки
		 *		periods: [ 		// - required
		 *			from: string,
		 *			to: string
		 *		][]
		 *	}[]
		 * }}
		 */
		async addCartItemList({ targetProgramId, orders }){
			if(!orders?.length) return;

			try {
				const res = await api.postData({
					model: `target-program/cart/items/bulk-add-item`,
					data: { targetProgramId, orders }
				});
				console.log('addCartItemList', res);

				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({ error });
			}
			return;
		},
		async clearCart(){
			try {
				const res = await api.postData({
					model: `target-program/cart/clear`,
				});
				console.log('clearCart', res);
				if(res) this.cartData = null;

				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({ error });
			}
			return;
		},

		async addCartToTp({ targetProgramId, cartItemIds = [], deleteAppliedItems=false }){
			try {
				const res = await api.postData({
					model: `target-program/cart/apply`,
					data: { targetProgramId, cartItemIds, deleteAppliedItems },
				});
				console.log('addCartToTp', res);

				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({ error });
			}
			return;
		},

		async getTargetTabData(targetId){
			if(!targetId) return;

			try {
				const res = await api.getData({
					model: `target-program/tab/${targetId}`,
				});
				console.log('getTargetTabData', res);

				if(res?.data) this.targetTabData = res.data;

				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({error});
				return;
			}
		},

		clearTargetTabData(){
			this.targetTabData = null;
		},

		updateStructureData({ affectedSideIds, affectedStructureIds }:{ affectedSideIds?: number[], affectedStructureIds?:number[] } = {}){
			const { activeDesignView } = useRootStore();
			const { updateMapMarkers } = useMapDesignStore();
			const { updateStructureRow } = useGridDesignStore();

			// Обновление маркеров
			updateMapMarkers({
				structureIds: affectedStructureIds,
			});

			// Обновление строк таблицы и карточки РК
			updateStructureRow({
				structureIds: affectedStructureIds,
			});

		}
	},
});