<template lang="pug">

.default-view.home-view
	h1 Home page

</template>

<script>
export default {
	name: 'home-view'
}
</script>

<style lang="scss"></style>
