import ky from "ky";
import helper from "./helper";
import Pusher from "pusher-js";
import Echo from 'laravel-echo';
// import '@/mock-server/server';

class ServiceApi{
	constructor(){
		this.base = '/api/';
		let headers = {};

		const { hostname } = window.location;
		if(hostname.includes('localhost')){
			// this.base = 'https://outplan-dev.devtestprod.ru/api/';
			// this.base = 'https://outplan-test.devtestprod.ru/api/';

			/* разработческие токены:
				'dev_rim_erp' - 'ADMIN'
				'manager_rim_erp' - 'MANAGER'
				'head_rim_erp' - 'HEAD_OF_SALES'
			*/
			headers['x-dev-token'] = 'dev_rim_erp';
			// headers['x-dev-token'] = 'manager_rim_erp';
			// headers['x-dev-token'] = 'head_rim_erp';
			// headers['x-dev-token'] = 'partner_man_token';
		}

		this.api = ky.create({
			prefixUrl: this.base,
			timeout: 120_000,
			headers:{
				...headers,
			}
		});

		if(!(this.base.includes('outplan-test') || hostname.includes('localhost'))) this.initializeWebSocket();
	}

	initializeWebSocket() {
		window.Pusher = Pusher;

		const { hostname } = window.location;
		let wsHost = ENV.WS_HOST_PROD;
		let wsKey = ENV.WS_KEY_PROD;
		let authEndpoint = '/broadcasting/auth';

		if (hostname.includes('localhost')){
			wsHost = ENV.WS_HOST_DEV;
			wsKey = ENV.WS_KEY_DEV;
			// authEndpoint = 'https://outplan-dev.devtestprod.ru/broadcasting/auth';
		}
		else if (hostname.includes('outplan-dev')){
			wsHost = ENV.WS_HOST_DEV;
			wsKey = ENV.WS_KEY_DEV;
		}
		else if (hostname.includes('outplan-test')){
			wsHost = ENV.WS_HOST_TEST;
			wsKey = ENV.WS_KEY_TEST;
		}

		return new Promise((resolve, reject) => {
			this.echo = new Echo({
				broadcaster: "pusher",
				key: wsKey,
				authEndpoint,
				wsHost,
				wssPort: 443,
				wsPort: 6001,
				forceTLS: true,
				encrypted: true,
				disableStats: true,
				enableLogging: true,
				enabledTransports: ["ws", "wss"],
				cluster: "default",
				ws: true,
			});

			console.log("WebSocket initialized");
			resolve();
		});
	}

	getPrivateChannel(channelName) {
		if (!this.echo) {
			console.error("WebSocket is not initialized");
			return null;
		}
		console.log('connect to private channelName', channelName);
		return this.echo.private(channelName);
	}

	buildParams(params){
		const searchParams = new URLSearchParams();

		if(params) for(let [key, value] of Object.entries(params)){
			if (Array.isArray(value)) {
				// value.forEach(value => searchParams.append(`${key}[]`, value.toString()))
				// value.forEach(value => searchParams.append(`${key}`, value.toString()))
				searchParams.append(key, value.toString());
			} else {
				searchParams.append(key, value.toString());
			}
		}

		return searchParams;
	}

	normalizePath(url) {
		return url
			.replace(/\/+/g, '/') // Убирает дублирующиеся слэши
			.replace(/\/\.\//g, '/') // Убирает `/./`
			.replace(/\/[^/]+\/\.\.\//g, '/'); // Убирает `/../`
	}

	getParamsObject(params){
		const paramsObject = {};
		for (const [key, value] of params) {
			if (!paramsObject[key]) {
				paramsObject[key] = value;
			} else {
				if (Array.isArray(paramsObject[key])) {
					paramsObject[key].push(value);
				} else {
					paramsObject[key] = [paramsObject[key], value];
				}
			}
		}

		return paramsObject;
	}

	/**
	 * @param {{
	 * 	model: string,
	 * 	params?: object,
	 * 	isTimer?: boolean,
	 * }}
	 */
	async getData({ model, params, isTimer }){
		const label = `${helper.hash}-get-${model}`;
		const searchParams = this.buildParams(params);

		if(isTimer) console.time(label);
		const res = await this.api.get(model, { searchParams }).json();
		if(isTimer) console.timeEnd(label);
		// console.log(res);
		return res;
	}

	async getDataItem({ model, id, params }){
		const label = `${helper.hash}-get-${model}`;
		const searchParams = this.buildParams(params);

		console.time(label);
		const res = await this.api.get(`${model}/${id}`, { searchParams }).json();
		console.timeEnd(label);
		// console.log(res);
		return res;
	}


	/**
	 * @param {{
	 * 	model: string,
	 *  data?: object,
	 *  params?: object,
	 *  isTimer?: boolean
	 * }}
	 */
	async postData({ model, data={}, params, isTimer }){
		const label = `${helper.hash}-post-${model}`;
		const searchParams = this.buildParams(params);

		if(isTimer) console.time(label);
		const res = await this.api.post(model, { json: data, searchParams }).json();
		if(isTimer) console.timeEnd(label);
		// console.log(res);
		return res;
	}

	async postDataItem({ model, id, data, params }){
		const label = `${helper.hash}-post-${model}`;
		const searchParams = this.buildParams(params);

		console.time(label);
		const res = await this.api.post(`${model}/${id}`, { json: data, searchParams }).json();
		console.timeEnd(label);
		// console.log(res);
		return res;
	}

	async postFormData({ model, data }){
		const label = `${helper.hash}-postFormData-${model}`;
		const formData = new FormData();

		for( const [key, value] of Object.entries(data)){
			formData.append(key, value);
		}

		console.time(label);
		const res = await this.api.post(model, { body: formData }).json();
		console.timeEnd(label);

		return res;
	}

	async putData({ model, id='', data, params }){
		const label = `${helper.hash}-put-${model}`;
		const searchParams = this.buildParams(params);

		console.time(label);
		const res = await this.api.put(`${model}/${id}`, { json: data, searchParams }).json();
		console.timeEnd(label);
		// console.log(res);
		return res;
	}

	async deleteData({ model, id, params }){
		const label = `${helper.hash}-delete-${model}`;
		const searchParams = this.buildParams(params);

		console.time(label);
		const res = await this.api.delete(`${model}/${id}`, { searchParams }).json();
		console.timeEnd(label);
		// console.log(res);
		return res;
	}

	async getFileData({ path, params }){
		const label = `${helper.hash}-getFile-${path}`;
		const searchParams = this.buildParams(params);

		console.time(label);
		const res = await this.api.get(path, { searchParams }).blob();
		console.timeEnd(label);
		// console.log(res);
		return res;
	}

}

export default new ServiceApi();
