import { defineComponent } from 'vue';

import { ElPagination } from 'element-plus';
import BsSingleSelect from '@/app/components/controls/selects/bs-single-select/BsSingleSelect.vue';

export default defineComponent({
	name: 'grid-pagination',
	components: {
		BsSingleSelect, ElPagination,
	},
	props: {
		currentPage: {
			type: Number,
			default: 1,
		},
		total: {
			type: Number,
			default: 0,
		},
		sizeList: {
			type: Array,
			default: ()=>[
				{
					value: '20',
					label: '20',
				},
				{
					value: '30',
					label: '30',
				},
				{
					value: '50',
					label: '50',
				},
				{
					value: '70',
					label: '70',
				},
				{
					value: '100',
					label: '100',
				},
				{
					value: '150',
					label: '150',
				},
				{
					value: '200',
					label: '200',
				},
			],
		},
		currentSize: {
			type: String,
			default: '50',
		}
	},
	emits: ['update:currentPage', 'change'],
	data() {
		return {
			curSize: this.currentSize,
			curPage: this.currentPage,
		}
	},
	methods: {
		currentChange(){
			this.$emit('change', { page: this.curPage, sizeChunk: Number(this.curSize)});
		},
		changeSize(val){
			console.log(val);
			this.$emit('change', { page: this.curPage, sizeChunk: Number(val)});
		},
	},
	created(){
		const perPage = JSON.parse(localStorage.getItem('perPage'));
		if(perPage) {
			this.curSize = perPage;
		} else {
			this.curSize = this.currentSize;
		}
	},
	watch: {
		curSize(val){
			// this.$emit('change', { page: this.curPage, sizeChunk: Number(val)});
			localStorage.setItem('perPage', val);
		},
		currentPage(val){
			this.curPage = val;
		}
	},
});