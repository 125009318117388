import { defineComponent } from 'vue';
import { mapState, mapActions } from 'pinia';
import { useRootStore } from './stores/root-store';
import { useUserStore } from './stores/user-store';
import { useFiltersStore } from './stores/filters-store';

import { ElConfigProvider } from 'element-plus';

import localeRu from 'element-plus/dist/locale/ru.js';
import dayjs from 'dayjs';
import objectSupport from 'dayjs/plugin/objectSupport';
// import objectDuration from 'dayjs/plugin/duration';
import objectWeekday from 'dayjs/plugin/weekday';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import "dayjs/locale/ru";

// dayjs.extend(objectDuration);
dayjs.extend(customParseFormat);
dayjs.extend(objectWeekday);
dayjs.extend(objectSupport);
dayjs.locale('ru');

// Установка первого дня недели в календарях
dayjs.Ls.en.weekStart = 1;
dayjs.Ls.ru ??= {} as ILocale;
dayjs.Ls.ru.weekStart = 1;

import DefaultLayout from '@/app/layouts/default-layout/DefaultLayout.vue';
import AuthLayout from '@/app/layouts/auth-layout/AuthLayout.vue';

export default defineComponent({
	name: 'app',
	components: {
		DefaultLayout, AuthLayout, ElConfigProvider,
	},
	data() {
		return {
			locale: localeRu,
		};
	},
	computed: {
		...mapState(useUserStore, ['roles']),

		layout(){
			return this.$route?.meta?.layout || 'default-layout';
		}
	},
	methods: {
		...mapActions(useUserStore, ['getUser', 'hasRoles']),
		...mapActions(useFiltersStore, ['loadEnumOptions']),

		/** Инициализация событий сочетания клавиш */
		initKeyEvents() {
			$$(window).on('keydown', (e)=>{
				// console.log(e);

				// Ctrl+Enter - перезагрузка страницы
				// if (e.ctrlKey && e.code == 'Enter') {
				// 	this.reload();
				// }

				// Alt+'\' - устанавливает флаг разработчика в localStorage
				if (e.altKey && e.code == 'Backslash') {
					console.log('setLocalStorage');
					window.localStorage.setItem('dev', 'true');
					this.reload();
				}
			});
		},

		/** Перезагрузка вкладки */
		reload() {
			window.location.reload();
		},

		handleSelect(key, keyPath){
			console.log(key, keyPath)
		}
	},
	watch:{
		roles: {
			handler(){
				const { name } = this.$route;

				if((this.hasRoles('MANAGER') || this.hasRoles('HEAD_OF_SALES'))){
					// console.info('permissions - MANAGER, HEAD_OF_SALES');
					if(name=='pricing-policies') this.$router.push({ name: 'structures' });
				}
			},
			immediate: true,
		},
	},
	async created() {
		this.initKeyEvents(); // инициализация событий сочетания клавиш
	},
})