import { defineStore } from 'pinia';
// import { useDesignFilterStore } from './design-filter-store';
import { useFiltersStore } from '../filters-store';

import dayjs from 'dayjs';

import router from '@/app/router';
import api from '@/app/utils/service-api';
import helper from '@/app/utils/helper';
import errorService from '@/app/utils/error-service';

import { defaultDataAdapter, personDataAdapter,
	summaryDataAdapter, otherDataAdapter, changeOrderMonthAdapter } from '@/app/stores/adapters/target-program/stats-data-adapter';

import { ElMessageBox } from 'element-plus';
// import { targetMockData } from '@/mock-server/data/get-target-data';

const createOptionEmpty = (adapterData)=>{
	const optionsData = {};
	for( const {key, keyBack} of adapterData){
		optionsData[key] = [];
	}
	return optionsData;
};

export const useDetailTargetProgStore = defineStore('detailTargetProgStore', {
	state() {
		return {
			isAsideLoader: true,
			isViewLoader: true,
			isShowViewMatrix: false,
			isShowSummaryTable: false,
			isShowChatPanel: false,

			detailTargetStatsData: null,
			detailTargetGridTotals: null,
			detailTargetPaginationData: null,
			detailTargetFilterData: {
				city: { value: '' },
				searchGidData: '',
				placePeriodDate: {
					from: '',
					to: '',
				},
				direction: { value: [] },
				structureCategory: { value: '' },
				constructType: { value: '' },
				mechanism: { value: [] },
				lighting: { value: [], options: [
					{
						value: 1,
						label: 'Да'
					},
					{
						value: 0,
						label: 'Нет'
					},
				]},

				statusAgreement: { value: '', options: [
					{
						value: 'draft',
						label: 'Ожидание отправки',
					},
					{
						value: 'approved',
						label: 'Согласовано',
					},
					{
						value: 'rejected',
						label: 'Отказ',
					},
					{
						value: 'pending',
						label: 'На согласовании',
					},
				]},
				size: { value: '' },
				status: { value: '' },
				expiringStatus: { value: '' },
				serviceStatus: { value: '' },
				countFreeDays: '',
				countFreeSurface: '',
				nets: { value: [] },

				operator: { value: '' },
				owner: { value: '' },
				priceMonth: { select: '', input: '' },
				ots: { select: '', input: '' },
				grp: { select: '', input: '' },
				pricePlacement: { select: '', input: '' },

				// Person
				rimPerson: { value: '' },
				clientPerson: { value: '' },
				installPerson: { value: '' }, // Монтаж
				printPerson: { value: '' }, // Печать
				expPrintPerson: { value: '' }, // Срочная печать
				designPerson: { value: '' }, // Дизайн
				photoReportPerson: { value: '' }, // Фотоотчет

				// Digital
				showsInHour: { value: '' }, // Выходов в час
				blockDuration: { value: '' },
				slotDuration: { value: '' },
				digitalVideoLength: '', // длина ролика
				wifiTrap: { value: '' }, // wifi ловушка

				// Остальное
				additionPeriodDate: {
					from: '',
					to: '',
				},
				tags: { value: '' }, // тэги
				manager: { value: '' }, // менеджер
				placementType: { value: '' }, //тип размещения
				customerCategory: { value: '' }, // категория клиента
				product: { value: '' }, // продукт
				ids: [], // ID строк заказа
				hideWithoutPeriods: { value: true }, // остави период

				// Стоимость доп. услуг
				installPrice: { select: '', input: '' }, // монтаж
				addInstallPrice: { select: '', input: '' }, // доп. монтаж
				printPrice: { select: '', input: '' }, // печать
				printExpPrice: { select: '', input: '' }, // срочная печать
				designPrice: { select: '', input: '' }, // дизайн
				photoReportPrice: { select: '', input: '' }, // Фотоотчет
				totalPrice: { select: '', input: '' }, // Общая стоимость
				agentCommission: { select: '', input: '' }, // СК агентское
				customerCommission: { select: '', input: '' }, // СК клиентское

				// countDigitalSurfaces: { value: '', options: [] },
				// countSelectedSurfaces: { value: '', options: [] },

			},
			detailSortData: null,
			// Тип периодов размещения
			placePeriodType: {
				value: 'day',
				options: [
					{
						value: 'month',
						label: 'По месяцам',
					},
					{
						value: 'day',
						label: 'По дням',
					},
				],
			},
			compareOptions: [
				// {
				// 	label: '=',
				// 	value: '='
				// },
				{
					label: '<',
					value: '<'
				},
				{
					label: '>',
					value: '>'
				},
			],
			vatOptions: [
				{
					value: 'yes',
					label: 'Да',
				},
				{
					value: 'no',
					label: 'Нет',
				},
				{
					value: 'unknown',
					label: 'Неизвестно',
				},
			],

			detailTargetOptionsData: {
				summary: createOptionEmpty(summaryDataAdapter),
				person: createOptionEmpty(personDataAdapter),
			},
			managerList: [],
			statusList: [],

			placementTypeList: [],
			detailMonthSelectData: {
				value: '',
				options: [],
			},
			detailTargetGridData: [],
			targetTabData: null,
			coordinationData: [],
			approvalTopBarData: null,
			approvalMonthData: null,
			textIds: '',

			allCheckSettingsDetailTable: '',
		};
	},

	getters: {
		countDetailTargetFilter(){
			let total = 0;

			const addSelectParams = ({ filterData }) => {
				const { value } = filterData;
				if(value?.length || (!Array.isArray(value) && value)) total++;
			};

			for(const [ key, filterData ] of Object.entries(this.detailTargetFilterData)){
				if( ['placePeriodDate', 'additionPeriodDate'].includes(key) ){
					const { from, to } = filterData;
					if( from || to ) total++;
				}
				else if([ 'countFreeDays', 'countFreeSurface', 'digitalVideoLength', ].includes(key)){
					if(filterData) total++;
				}
				else if(['priceMonth', 'ots', 'grp', 'pricePlacement',
					'installPrice', 'addInstallPrice', 'printPrice', 'printExpPrice',
					'designPrice', 'photoReportPrice', 'totalPrice',
					'agentCommission', 'customerCommission',
				].includes(key)){
					const { select, input } = filterData;
					if( input || (select && input) ) total++;
				}
				else if(key=='searchGidData'){
					// console.log(key, filterData);
					if(filterData.gids?.length) total++;
				}
				else if(key=='ids'){
					// console.log(key, filterData);
					if(filterData?.length) total++;
				}

				// options
				else addSelectParams({filterData});
			}

			return total;
		},
		managerMap(){
			const map = {};
			for( const item of this.managerList ){
				map[item.id] = item;
			}
			return map;
		},
		allAdapterMap(){
			const listAll = [ ...defaultDataAdapter, ...personDataAdapter, ...summaryDataAdapter ];
			const map = {};

			for(const item of listAll){
				map[item.key] = item;
			}

			return map;
		},
	},
	actions: {
		getTableSettingsLocStorage(){
			const data = window.localStorage.getItem('settings--target-detail-table');
			return JSON.parse(data);
		},
		async getTargetPeriod(targetProgramId){
			try {
				const res = await api.getData({
					model: `target-program/${targetProgramId}/period`,
				});
				if(res?.period){
					const { period } = res;
					const selectOptions = this.buildDetailMonthSelectOptions(period);

					if (period?.from) {
						const formattedDate = dayjs(period.from).format('YYYY-MM');

						if(this.detailMonthSelectData.value) {
							const closestMonth = this.getClosestMonthValue(selectOptions, this.detailMonthSelectData.value)
							this.detailMonthSelectData.value = closestMonth;
						} else {
							this.detailMonthSelectData.value = formattedDate;
						}
					}
				}
				console.log('getTargetPeriod', res);
				return res;
			} catch (error) {
				console.error(error);
			}
			return;
		},
		setTableSettingsLocStorage(config){
			if(!config) return;
			const data = {};
			const { columns, isCollapsed, isCompact } = config;

			for(const item of columns){
				if(item.show === undefined) continue;
				const { key, show } = item;
				data[key] = show;
			}

			// console.log(data);
			try {
				const settings = { colConfig: data, isCollapsed, isCompact };
				window.localStorage.setItem('settings--target-detail-table', JSON.stringify(settings));

				return settings;
			} catch (error) {
				console.error(error);
			}

			return;
		},

		getClosestMonthValue(options, targetValue) {
			const sortedOptions = options.sort((a, b) => a.value.localeCompare(b.value));

			for (const option of sortedOptions) {
				if (option.value >= targetValue) {
					return option.value;
				}
			}

			return sortedOptions[sortedOptions.length - 1].value;
		},

		async buildDetailTargetStatsData(data){
			const filterStore = useFiltersStore();
			const { getOptions } = filterStore;
			const {
				id, dealId, bitrix_deal_url, name, pricePolicy,
				summary, legalEntities, stats,
			} = data;
			const defaultData = {
				id, dealId,
				name: name ?? '',
				b24DealLink: bitrix_deal_url,
			};
			const summaryData = {};
			const personData = {};
			let statsData = {};
			let metricsData = {};
			let placementsData = {};

			if(summary){
				for(const { key, keyBack, keyOptions } of summaryDataAdapter){
					let value = summary?.[keyBack]?.id ?? '';

					if(key=='period'){
						value = summary?.[keyBack];
						summaryData[key] = value ?? {from: '', to: ''};

						// Перенесено в 'getTargetPeriod'
						// const selectOptions = this.buildDetailMonthSelectOptions(value);
						// if (value?.from) {
						// 	const formattedDate = dayjs(value.from).format('YYYY-MM');
						// 	if(this.detailMonthSelectData.value) {
						// 		const closestMonth = this.getClosestMonthValue(selectOptions, this.detailMonthSelectData.value)
						// 		this.detailMonthSelectData.value = closestMonth;
						// 	} else {
						// 		this.detailMonthSelectData.value = formattedDate;
						// 	}
						// }
					}
					else if(['responsibleManager', 'observerManagers'].includes(key)){
						if(key=='observerManagers'){
							if(summary?.[keyBack]?.length){
								value = summary?.[keyBack].map(item=>item.id);
							} else {
								value = [];
							}
						}

						summaryData[key] = {
							value,
							options: this.managerList,
						};
					}
					else {
						summaryData[key] = {
							value,
							options: getOptions(keyOptions),
						};
					}
				}
			}

			if(summary?.metricsSummary) metricsData = summary.metricsSummary;
			if(summary?.placements) placementsData = summary.placements;

			if(legalEntities){
				for(const { key, keyBack, keyOptions } of personDataAdapter){
					const value = legalEntities?.[keyBack]?.id ?? '';
					// if(value && !Array.isArray(value)) value = [value];

					personData[key] = {
						value,
						options: getOptions(keyOptions),
					};
				}
			}

			if(stats){
				statsData = stats;
			}

			// Получение опций прайсовых политик
			await (async ()=>{
				const id = pricePolicy?.id ?? '';
				const customerLegalEntityId = legalEntities?.customerLegalEntity?.id ?? '';
				const res = await this.getPricePolicyOptions(customerLegalEntityId);
				let options = [];

				// console.log('getPricePolicyOptions', res);
				if(res?.data?.length) options = res.data.map((item)=>{
					return {
						value: item.id,
						label: item.name,
					};
				});

				const findItem = options.find(item=>item.value == id);
				if(id && !findItem) options.push({
					value: id,
					label: pricePolicy?.name ?? 'no-name',
				});

				defaultData['pricePolicy'] = {
					value: id,
					options,
				};
			})();

			return { defaultData, summaryData, personData, statsData, metricsData, placementsData, rawData: data };
		},
		async getDetailTargetStatsData(id){
			if(!id) return;

			this.isAsideLoader = true;
			try {
				const res = await api.getData({ model: `target-program/${id}/stats` });
				console.log('getDetailTargetStatsData', res);

				if(res?.data) this.detailTargetStatsData = await this.buildDetailTargetStatsData(res.data);
				console.log('getDetailTargetStatsData', this.detailTargetStatsData);

				this.isAsideLoader = false;
				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({ error });
			}

			this.isAsideLoader = false;
			return;
		},

		buildDetailTargetFilterData(data){
			if(!data) return;
			const buildOptionsData = ({ data, value=[]})=>{
				const options = data.filter(item=>(item.value && item.label));
				return { value, options };
			};

			for(const [key, filterData] of Object.entries(data)){
				if(!this.detailTargetFilterData?.[key]) continue;

				// options
				this.detailTargetFilterData[key] = buildOptionsData({ data:filterData });
			}

			console.log('detailTargetFilterData', this.detailTargetFilterData);
		},
		async getDetailTargetFilterData(){
			try {
				const res = await api.getData({ model: 'order/filter-options' });
				console.log('getDetailTargetFilterData', res);
				if(res?.data) this.buildDetailTargetFilterData(res.data);

				return res;
			} catch (error) {
				console.error(error);
			}

			return;
		},

		buildDetailTargetOptionsData(data){
			if(!data) return;
			const buildOptionsData = (adapterData)=>{
				let optionsData = {};
				for( const { key, keyBack } of adapterData){
					optionsData[key] = data?.[helper.camelToSnake(keyBack)] ?? [];
				}
				return optionsData;
			};

			this.detailTargetOptionsData = {
				summary: buildOptionsData(summaryDataAdapter),
				person: buildOptionsData(personDataAdapter),
				other: buildOptionsData(otherDataAdapter),
			};

			console.log(this.detailTargetOptionsData);
		},
		async getDetailTargetOptionsData(){
			try {
				const res = await api.getData({ model: 'target-program/create-options' });
				console.log('getDetailTargetOptionsData', res);
				if(res?.data) this.buildDetailTargetOptionsData(res.data);
				return res;
			} catch (error) {
				console.error(error);
			}
			return;
		},

		async getManagerList(id){
			if(!id) return;

			try {
				const res = await api.getData({ model: `target-program/${id}/manager-list` });
				console.log('getManagerList', res);
				if(res?.data){
					this.managerList = res.data.map(item=>{
						const { id, firstName, lastName } = item;
						const label = `${lastName ?? ''} ${firstName ?? ''}`.trim();

						return {
							...item,
							label,
							value: id,
						};
					});
				}

				return res;
			} catch (error) {
				console.error(error);
			}
			return;
		},

		async getStatusList(){
			try {
				const res = await api.getData({ model: 'order-month/statuses' });
				console.log('getStatusList', res);

				if(res?.data && Object.keys(res.data)?.length) this.statusList = Object.values(res.data);
				return res;
			} catch (error) {
				console.error(error);
			}
			return;
		},

		/** Обновление данных в Aside панели детальной АП */
		async updateDetailTargetAsideItem({ fieldKey, value }={}){
			console.log({ fieldKey, value });

			if(!fieldKey || value===undefined) return;
			this.isAsideLoader = true;
			const { defaultData } = this.detailTargetStatsData ?? {};
			const { id } = defaultData ?? {};
			const adapterItem = this.allAdapterMap?.[fieldKey];
			const { keyBackUpdate } = adapterItem ?? {};

			if(!keyBackUpdate || !id) return;

			try {
				const res = await api.postData({
					model: `target-program/${id}/update`,
					data:{
						[keyBackUpdate]: value,
					},
				});

				console.log('updateDetailTargetAsideItem', fieldKey, res);
				this.isAsideLoader = false;
				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({ error });
			}

			this.isAsideLoader = false;
			return;
		},

		async updateDetailTargetAsideResponsible(userId){
			if(!userId) return;
			this.isAsideLoader = true;

			const { defaultData } = this.detailTargetStatsData ?? {};
			const { id } = defaultData ?? {};

			try {
				const res = await api.postData({
					model: `target-program/${id}/change-responsible-manager`,
					data: { userId }
				});

				console.log('updateDetailTargetAsideResponsible', res);
				this.isAsideLoader = false;
				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({ error });
			}

			this.isAsideLoader = false;
			return;
		},

		async addDetailTargetAsideManager(userIds){
			if(!userIds?.length) return;
			this.isAsideLoader = true;

			const { defaultData } = this.detailTargetStatsData ?? {};
			const { id } = defaultData ?? {};

			try {
				const res = await api.postData({
					model: `target-program/${id}/add-manager`,
					data: { userIds }
				});

				console.log('addDetailTargetAsideManager', res);
				this.isAsideLoader = false;
				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({ error });
			}

			this.isAsideLoader = false;
			return;
		},

		async removeDetailTargetAsideManager(userId){
			if(!userId) return;
			this.isAsideLoader = true;

			const { defaultData } = this.detailTargetStatsData ?? {};
			const { id } = defaultData ?? {};

			try {
				const res = await api.postData({
					model: `target-program/${id}/remove-manager`,
					data: { userId }
				});

				console.log('removeDetailTargetAsideManager', res);
				this.isAsideLoader = false;
				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({ error });
			}

			this.isAsideLoader = false;
			return;
		},


		/*
			{
				value: '2023-12',
				label: 'Декабрь’23',
				count: 17,
			},
		*/
		buildDetailMonthSelectOptions(period){
			const { from, to } = period ?? {};
			if(!(from && to)) return;
			const options = [];
			const fromDate = dayjs(from);
			const toDate = dayjs(to);
			const countMonth = toDate.diff(fromDate, 'month');

			for (let i=0; i<=countMonth; i++) {
				const date = fromDate.add(i, 'month');
				const yearMonth = date.format('YYYY-MM');
				const label = date.format('MMMM’YY');

				options.push({
					value: yearMonth,
					label: helper.upFirstLetter(label),
					count: 0,
				});
			}

			console.log({countMonth, options});
			this.detailMonthSelectData.options = options;
			return options;
		},

		setFilterFields(filters){
			if(!filters) return;

			const addSelectParams = ({ keyBack, value }) => {
				if(this.detailTargetFilterData[keyBack]){
					if(value === 0) this.detailTargetFilterData[keyBack].value = value;
					else this.detailTargetFilterData[keyBack].value = value || '';
				}
			};
			const addCompareField = ({ keyBack, value }) => {
				const [ select, input ] = value;
				if( input || (select && input) )  this.detailTargetFilterData[keyBack] = { select, input };
			};

			for(const [key, value] of Object.entries(filters)){
				// compare-input
				if(['priceMonth', 'ots', 'grp', 'pricePlacement',
					'installPrice', 'addInstallPrice', 'printPrice', 'printExpPrice',
					'designPrice', 'photoReportPrice', 'totalPrice',
					'agentCommission', 'customerCommission',
				].includes(key)) addCompareField({ keyBack: key, value });

				// input
				else if([ 'countFreeDays', 'countFreeSurface', 'digitalVideoLength' ].includes(key)) this.detailTargetFilterData[key] = value;

				// чекбокс
				// else if([ 'wifiTrap' ].includes(key)) this.detailTargetFilterData[key] = !!value;

				else if(['placePeriodDate', 'additionPeriodDate'].includes(key)) this.detailTargetFilterData[key] = value;
				else if(key=='gids'){
					const { gids, gidSearchBy, allowPartialMatch } = filters;
					this.detailTargetFilterData.searchGidData = {
						gids, gidSearchBy,
						allowPartialMatch: !!allowPartialMatch,
					};
				}
				else if(key=='ids'){
					this.textIds = value;
				}
				// options
				else addSelectParams({ keyBack: key, value });
			}
		},

		clearFilters(){
			for( const [key, filterData] of Object.entries(this.detailTargetFilterData) ){
				// compare-input
				if(['priceMonth', 'ots', 'grp', 'pricePlacement',
					'installPrice', 'addInstallPrice', 'printPrice', 'printExpPrice',
					'designPrice', 'photoReportPrice', 'totalPrice',
					'agentCommission', 'customerCommission',
				].includes(key)) this.detailTargetFilterData[key] = { select: '', input: '' };

				else if( ['placePeriodDate', 'additionPeriodDate'].includes(key) ) this.detailTargetFilterData[key] = { from: '', to: '' };
				else if(key=='searchGidData') this.detailTargetFilterData[key] = '';
				// input
				else if([ 'countFreeDays', 'countFreeSurface', 'digitalVideoLength' ].includes(key)){
					this.detailTargetFilterData[key] = '';
				}
				// else if(key=='wifiTrap') this.detailTargetFilterData[key] = false;
				else if(key=='hideWithoutPeriods') this.detailTargetFilterData[key].value = true;
				else if(key=='ids'){
					this.textIds = '';
				}
				// select
				else if(Array.isArray(filterData.value)) filterData.value = [];
				else filterData.value = '';
			}
		},

		getSubmitDetailFilterData({ isAllFields }={}){
			const data = {};
			const addSelectField = ({ keyBack, filterData }) => {
				const { value } = filterData;
				if(value?.length || (!Array.isArray(value) && value) || value===0) data[keyBack] = value;
				else if(isAllFields) data[keyBack] = '';
			};
			const addCompareField = ({ keyBack, filterData }) => {
				const { select, input } = filterData;
				if( input || (select && input) ) data[keyBack] = [ select??'', input??'' ];
			};


			for( const [key, filterData] of Object.entries(this.detailTargetFilterData) ){
				// compare-input
				if(['priceMonth', 'ots', 'grp', 'pricePlacement',
					'installPrice', 'addInstallPrice', 'printPrice', 'printExpPrice',
					'designPrice', 'photoReportPrice', 'totalPrice',
					'agentCommission', 'customerCommission',
				].includes(key)) addCompareField({ keyBack: key, filterData });

				else if( ['placePeriodDate', 'additionPeriodDate'].includes(key) ){
					const { from, to } = filterData;
					if( from || to ) data[key] = filterData;
				}

				else if(key=='searchGidData'){
					// console.log(filterData);
					const { gids, gidSearchBy, allowPartialMatch } = filterData;
					if( gids?.length ){
						data['gids'] = gids ?? '';
						data['gidSearchBy'] = gidSearchBy ?? '';
						data['allowPartialMatch'] = allowPartialMatch ?? '';
					}
				}
				// input
				else if([ 'countFreeDays', 'countFreeSurface', 'digitalVideoLength'].includes(key)){
					if(filterData) data[key] = filterData;
				}
				else if([ 'ids' ].includes(key)){
					if(filterData?.length) data[key] = filterData;
				}

				// select
				else addSelectField({ keyBack: key, filterData });
			}

			return data;
		},

		buildDetailTargetGridData(data, permittedToOverbound){
			if(!data?.length) return [];
			const detailTargetGridData = [];

			for(const item of data){
				const { side, order, month } = item;
				const sideData = side;
				const orderData = order;
				const monthData = {};

				if(month && Object.keys(month).length){
					for(const [key, fieldData] of Object.entries(month)){
						// if(['placementType'].includes(key)){
						// 	if(!fieldData?.value) monthData[key] = { value: '' };
						// 	else monthData[key] = fieldData;
						// } else monthData[key] = fieldData;
						monthData[key] = fieldData;
					}
				}

				detailTargetGridData.push({ sideData, orderData, monthData,
					permittedToOverbound: !!permittedToOverbound, // разрешение пользователя выходить за пределы в диджиталке
				});
			}
			// console.log(123);

			return detailTargetGridData;
		},

		async getDetailTargetGridData({ id, monthDate='', filters, sort, params={} }){
			if(!id) return;

			const data = {};
			this.isViewLoader = true;

			// Установка дефолтных параметров
			if(!monthDate && this.detailMonthSelectData?.value) monthDate = this.detailMonthSelectData.value;
			if(filters){
				data['filters'] = filters;
			} else {
				data['filters'] = this.getSubmitDetailFilterData();
			}

			if(sort) {
				data['sort'] = sort;
			} else if(this.detailSortData && Object.keys(this.detailSortData).length > 0) {
				data['sort'] = this.detailSortData;
			}

			try {
				const res = await api.postData({ model: 'order/list', params,
					data: {
						...data,
						targetProgramId: id,
						yearMonth: monthDate,
					},
				});
				console.log('getDetailTargetGridData', res);

				if(res?.data) this.detailTargetGridData = this.buildDetailTargetGridData(res?.data, res?.permittedToOverbound);
				// if(res?.categoriesCount) this.allTargetCategoriesCount = res.categoriesCount;
				if(res?.meta) this.detailTargetPaginationData = res.meta;
				if(res?.totals) this.detailTargetGridTotals = res.totals;

				this.getCoordinationData();

				this.isViewLoader = false;
				return res;
			} catch (error) {
				console.error(error);

				ElMessageBox.alert( null, null,
					{
						title: 'Ошибка!',
						message: 'Список датагрида не может быть получен',
						type: 'error',
						customClass: 'bs-message-box',
						showClose: false,
					}
				);
			}

			this.isViewLoader = false;
			return;
		},

		async denyApproval(approvaId) {
			try {
				const res = await api.postData({
					model: `approval/${approvaId}/deny-approval`
				});

				return res;
			} catch (error) {
				console.error(error);
				return;
			}
		},

		async deleteApproval(approval) {
			const rowItemIndex = this.detailTargetGridData.findIndex(
				(item) => item?.monthData?.id === approval?.approvableId
			);
			if (rowItemIndex === -1) return;

			const approvalIndex = this.detailTargetGridData[rowItemIndex]?.monthData?.approvals.findIndex(
				(item) => item?.id === approval?.id
			);
			if (approvalIndex === -1) return;

			this.detailTargetGridData[rowItemIndex].monthData.approvals.splice(approvalIndex, 1);
		},

		async getPlacementTypeList(){
			try {
				const res = await api.getData({ model: 'placement-type/list' });
				console.log('getPlacementTypeList', res);
				if(res?.data) this.placementTypeList = res.data;

				return res;
			} catch (error) {
				console.error(error);
			}
			return;
		},

		async getChangeError(error){
			if (error.name === 'HTTPError' && [403, 422].includes(error.response.status)) {
				const errorJson = await error.response.json();
				const errMessage = errorJson?.message;

				return { message: errMessage };
			}

			return;
		},

		async changeFieldOrderMonth({ orderId, orderMonthId, key, fieldData = [], addParams = {}, withoutApproval }){
			if(!(orderId && key)) return;
			const field = changeOrderMonthAdapter?.[key];
			const yearMonth = this.detailMonthSelectData.value;
			console.log({orderId, orderMonthId, yearMonth, key, fieldData});

			if(!field) return;
			const monthData =  {
				orderId, orderMonthId, yearMonth,
				[field.keyField]: fieldData,
				...addParams,
			};
			if(withoutApproval) monthData.withoutApproval = true;
			try {
				const res = await api.postData({
					model: `order-month/${field.param}`,
					data: monthData
				});
				console.log('changeFieldOrderMonth', {orderMonthId, key}, res);

				return res;
			} catch (error) {
				console.error(error);
				return await errorService.getFetchMessage({error});
			}
		},

		async changeAdditionalServiceData({ orderId, orderMonthId, key, fieldData }){
			if(!(orderId && key)) return;
			const field = changeOrderMonthAdapter?.[key];
			const yearMonth = this.detailMonthSelectData.value;

			console.log({orderId, orderMonthId, yearMonth, key, fieldData, field});
			if(!field) return;
			try {
				const res = await api.postData({
					model: 'order-month/change-additional-service-data',
					data: {
						orderId, orderMonthId, yearMonth,
						...fieldData,
						serviceKey: field.keyField,
					}
				});
				console.log('changeAdditionalServiceData', {orderMonthId, key}, res);

				return res;
			} catch (error) {
				console.error(error);
				await errorService.getFetchMessage({error});
				throw error;
			}
		},

		async changeFieldComment({ orderId, comment }){
			if(!orderId) return;

			try {
				const res = await api.postData({
					model: `order/change-comment`,
					data: { orderId, comment },
				});
				this.changeCoordinationDataItemComment(orderId, comment);
				return res;
			} catch (error) {
				console.error(error);
				return await errorService.getFetchMessage({error});
			}
		},

		async changeInitiatorComment({ approvalId, comment }){
			if(!approvalId) return;

			try {
				const res = await api.postData({
					model: `approval/${approvalId}/initiator-comment`,
					data: { comment },
				});
				this.changeCoordinationApproval(approvalId, comment);
				return res;
			} catch (error) {
				console.error(error);
				return await errorService.getFetchMessage({error});
			}
		},

		async changeFieldDuration({ orderMonthId, orderId, duration }){
			if(!orderId) return;

			try {
				const res = await api.postData({
					model: `order/change-duration`,
					data: { orderMonthId, orderId, duration },
				});
				console.log('changeFieldComment', { orderMonthId, orderId, duration }, res);

				return res;
			} catch (error) {
				console.error(error);
				return await errorService.getFetchMessage({error});
			}
		},

		async changeStatusUntil({ orderId, orderMonthId, date }){
			if(!orderId) return;

			const yearMonth = this.detailMonthSelectData.value;

			try {
				const res = await api.postData({
					model: 'order-month/change-status-until',
					data: {
						orderId, orderMonthId, yearMonth,
						statusUntil: date
					}
				});
				console.log('changeStatusUntil', { orderId, orderMonthId, date }, res);

				return res;
			} catch (error) {
				console.error(error);
				return await errorService.getFetchMessage({error});
			}
		},

		async getAddServPrice({ orderId, orderMonthId, key }){
			if(!(orderId && key)) return;
			const field = changeOrderMonthAdapter?.[key];
			const yearMonth = this.detailMonthSelectData.value;

			console.log({orderId, orderMonthId, yearMonth, key });
			if(!field) return;
			try {
				const res = await api.postData({
					model: `order-month/addserv-price`,
					data: {
						orderId, orderMonthId, yearMonth,
						serviceKey: field.keyField
					}
				});
				console.log('getAddServPrice', {orderMonthId, key}, res);

				return res;
			} catch (error) {
				console.error(error);
				return await errorService.showMessageBox({error});
			}
		},

		updateDetailTargetRowData({rowData, data }){
			if(!data) return;
			const buildData = this.buildDetailTargetGridData([data]);
			if(!buildData?.length) return;
			const { sideData, orderData, monthData } = buildData[0];

			rowData['sideData'] = sideData;
			rowData['orderData'] = orderData;
			rowData['monthData'] = monthData;
		},

		async massChange({ type, data={} }){
			if(!type){
				console.error('Не задан тип изменения');
				return;
			}

			// Если выбран режим таблицы 'Выбрать всё'
			if(this.allCheckSettingsDetailTable == 'check-all-page'){
				const route = router.currentRoute.value;
				const targetProgramId = Number(route?.params?.id ?? 0);
				const filters = this.getSubmitDetailFilterData();
				const orderIds = await this.getAllPageOrders({
					filters, targetProgramId
				});

				data['orderIds'] = orderIds;
			}

			try {
				const res = await api.postData({
					model: `order-month/${type}`,
					data,
				});

				console.log('massChange', {type, data}, res);

				return res;
			} catch (error) {
				console.error(error);
				return await errorService.getFetchMessage({error});
			}
		},
		async massOrderDelete(orderIds){
			if(!orderIds) return;

			if(this.allCheckSettingsDetailTable == 'check-all-page'){
				const route = router.currentRoute.value;
				const targetProgramId = Number(route?.params?.id ?? 0);
				const filters = this.getSubmitDetailFilterData();

				orderIds = await this.getAllPageOrders({
					filters, targetProgramId
				});
			}

			try {
				const res = await api.postData({
					model: `order/bulk-delete`,
					data: { orderIds }
				});

				console.log('massDelete', { orderIds }, res);
				return res;
			} catch (error) {
				console.error(error);
				return await errorService.getFetchMessage({error});
			}
		},

		/** Массовое изменение снятие статуса */
		async cancelStatus(data={}){
			// Если выбран режим таблицы 'Выбрать всё'
			if(this.allCheckSettingsDetailTable == 'check-all-page'){
				const route = router.currentRoute.value;
				const targetProgramId = Number(route?.params?.id ?? 0);
				const filters = this.getSubmitDetailFilterData();
				const orderIds = await this.getAllPageOrders({
					filters, targetProgramId
				});

				data['orderIds'] = orderIds;
			}

			try {
				const res = await api.postData({
					model: `approval/bulk-approval-by-status`,
					data,
				});
				console.log(res);
				return res;
			} catch (error) {
				console.error('cancelStatus', error);
				errorService.showMessageBox({ error });
			}
		},

		async getFileData({ path, params, filename }){
			try {
				const blob = await api.getFileData({ path, params });

				const blobURL = URL.createObjectURL(blob);
				helper.createLink({ link: blobURL, filename });

				// Освобождение ресурсов URL-адреса ресурса
				URL.revokeObjectURL(blobURL);
				return blob;
			} catch (error) {
				console.error('Не удалось скачать ресурс:', error);
			}
			return;
		},

		setDetailSortData(sortData){
			this.detailSortData = sortData;
		},
		setCoordinationData(newCoordinationData) {
			this.coordinationData = newCoordinationData;
		},
		changeCoordinationApproval(approvalId, comment){
			const itemIndex = this.coordinationData.findIndex((dataItem, index) => {
				return dataItem.approvalId === approvalId;
			});

			if(this.coordinationData[itemIndex]) {
				this.coordinationData[itemIndex].comment = comment;
			}
		},
		changeCoordinationDataItem(newItem) {
			const monthItemIndex = this.detailTargetGridData.findIndex(targetItem => targetItem.monthData.id === newItem?.approvableId);

			const approvalData = this.detailTargetGridData[monthItemIndex]?.monthData?.approvals;
			if (approvalData) {
				const approvalIndex = approvalData.findIndex(item => item.id === newItem?.id);
				this.detailTargetGridData[monthItemIndex].monthData.approvals[approvalIndex] = {
					...this.detailTargetFilterData[monthItemIndex]?.monthData?.approvals[approvalIndex],
					...newItem
				};
				if (approvalIndex !== -1) {
					approvalData[approvalIndex] = newItem;
				}
			} else {
				console.error('changeCoordinationDataItem error:' , this.detailTargetGridData[monthItemIndex]?.monthData);
			}
		},
		changeCoordinationDataItemComment(orderId, comment) {
			const itemIndex = this.coordinationData.findIndex((dataItem, index) => {
				return dataItem.orderId === orderId;
			});

			if(this.coordinationData[itemIndex]) {
				this.coordinationData[itemIndex].comment = comment;
			}
		},

		async changeComment(orderId, comment){
			await this.changeFieldComment({ orderId, comment });
			const commentIndex = this.detailTargetGridData.findIndex((itemData) => itemData.orderData.id === orderId);
			this.detailTargetGridData[commentIndex].comment = comment;
		},

		getApprovalsByTypes(rowData){
			const sortedApprovals = rowData.monthData?.approvals?.slice().sort((a, b) => b.id - a.id) || [];
			const approvalsByType = sortedApprovals.reduce((acc, item) => {
				if (!acc[item.approvalType]) {
					acc[item.approvalType] = [];
				}
				acc[item.approvalType].push(item);
				return acc;
			}, {});
			return approvalsByType;
		},

		async getApprovalTopBarData({ targetProgramId, yearMonth }={}){
			if(!targetProgramId){
				const { params, name } = router.currentRoute.value;
				if(name == 'target-program-item') targetProgramId = params?.id;
				if(!targetProgramId) return;
			}

			if(!yearMonth){
				yearMonth = this.detailMonthSelectData?.value;
			}

			try {
				const res = await api.postData({
					model: 'approval/top-bar',
					data: {
						targetProgramId,
						yearMonth: yearMonth ?? '',
						// approvalTypes: '',
					}
				});
				console.log('getApprovalTopBarData', res);

				return res;
			} catch (error) {
				console.error(error);
			}

			return;
		},

		async getCoordinationData() {
			const result = [];

			const res = await this.getApprovalTopBarData();
			if(res) this.approvalTopBarData = res;


			/* TODO - возможно это deprecated, нужно проверить все вхождения использования coordinationData */
			for (const item of this.detailTargetGridData) {
				const approvals = this.getApprovalsByTypes(item);
				const approvalsHasTypes = approvals && typeof approvals === 'object' && Object.keys(approvals).length > 0;
				if(!approvalsHasTypes) continue;

				for (const [type, approvalList] of Object.entries(approvals)) {
					const approvalsTypeItem = approvalList[0];
					if(approvalsTypeItem?.status === 'draft') {
						const isNotNeedDiscountAnymore = approvalsTypeItem.approvalType === 'discount'
							&& (+item.monthData.discount <= +item.monthData.maxDiscount) && item.orderData?.comment?.length;

						if (isNotNeedDiscountAnymore && type === 'discount') {
							await this.changeComment(item.orderData.id, null);
							return;
						}
						result.push({
							approvalId: approvalsTypeItem?.id,
							approvalType: approvalsTypeItem?.approvalType,
							orderId: item?.orderData?.id,
							orderMonthId: item?.monthData?.id,
							discount: item?.monthData?.discount,
							allowedDiscount: item?.monthData?.maxDiscount,
							comment: approvalsTypeItem?.comment
						});
					}
				}
			}

			this.coordinationData = result;
			/* TODO */
		},

		async sendToAgreement(data){
			if(!data) return;

			try {
				const res = await api.postData({
					model: 'approval/create',
					data,
				});

				// if(res?.data) this.targetTabData = res.data;
				console.log('sendToAgreement', res);
				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({error});
				return;
			}
		},

		async getTargetTabData(targetId){
			if(!targetId) return;

			try {
				const res = await api.getData({
					model: `target-program/tab/${targetId}`,
				});
				console.log('getTargetTabData', res);

				if(res?.data) this.targetTabData = res.data;

				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({error});
				return;
			}
		},

		clearTargetTabData(){
			this.targetTabData = null;
		},

		async removeSideInTp({orderId, sideId}){
			if(!(orderId && sideId)) return;

			try {
				const res = await api.postData({
					model: `order/${orderId}/delete`,
					data: { sideId },
				});
				console.log('removeSideInTp', res);

				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({error});
				return;
			}
		},

		async searchTargetProgram(query){
			if(!query) return;

			try {
				const res = await api.getData({
					model: `target-program/tab/search`,
					params:{
						query: query.trim().toLowerCase(),
						limit: 30,
					}
				});
				console.log('searchTargetProgram', res);

				return res;
			} catch (error) {
				console.error(error);
				return;
			}
		},

		/**
		 * Добавление стороны в АП
		 * @param {{
		 * target_program_id: Number,
		 * orders: {
		 * 	side_id: Number,
		 * 	periods: [from: String, to: String][],
		 * 	duration: Number,
		 *  showsInHour: Number
		 * }[],
		 * }} data
		 */
		async addTargetProgram(data={}){
			try {
				const res = await api.postData({
					model: 'order/create',
					data,
				});

				console.log('addTargetProgram', res);
				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({ error });
			}

			return;
		},

		async getApprovalMonthData({ targetProgramId, filters }){
			const data = {};
			if(!filters){
				data['filters'] = this.getSubmitDetailFilterData();
			} else {
				data['filters'] = filters;
			}

			try {
				const res = await api.postData({
					model: `target-program/${targetProgramId}/approval-markers`,
					data,
				});
				console.log('getApprovalMonthData', res);
				if(res?.data?.length) this.approvalMonthData = null;
				else if(res?.data) this.approvalMonthData = res.data;

				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({ error });
			}

			return;
		},

		async sendMassAgreementDecision(data={}) {
			// Если выбран режим таблицы 'Выбрать всё'
			if(this.allCheckSettingsDetailTable == 'check-all-page'){
				const route = router.currentRoute.value;
				const targetProgramId = Number(route?.params?.id ?? 0);
				const filters = this.getSubmitDetailFilterData();
				const orderIds = await this.getAllPageOrders({ filters, targetProgramId });

				data['orderIds'] = orderIds;
			}

			try {
				const res = await api.postData({
					model: `approval/bulk-approval`,
					data,
				});
				console.log('sendMassAgreementDecision', res);
				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({ error });
			}
			return;
		},

		async sendMassAgreementDecisionTProg(type, comment) {
			const { id } = this.data?.notification?.targetProgram ?? '';
			try {
				const agreementType = type === 'approve' ? 'approved' : 'rejected';

				const res = await api.postData({
					model: `approval/approval-tp`,
					data: {
						targetProgramID: id,
						status: agreementType,
						approverComment: comment,
					},
				});
				console.log(res);
			} catch (error) {
				console.error('sendMassAgreementDecisionTProg', error);
				errorService.showMessageBox({ error });
			}
		},

		async getPricePolicyOptions(customerLegalEntityId=''){
			try {
				const res = await api.postData({
					model: `price-policy/options`,
					data: {
						customerLegalEntityId,
					},
				});
				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({ error });
			}
			return;
		},

		/** Скачать результат последнего импорта */
		async downloadLastImport(targetProgramId){
			if(!targetProgramId) return;

			try {
				const res = await api.getData({
					model: `target-program/${targetProgramId}/partner-import/result`,
				});

				if(res?.url) helper.createLink({
					link: res.url,
				});
				console.log('downloadLastImport', res);
				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({ error });
			}
			return;
		},

		/** Отдача orders всех страниц списка по заданным фильтрам */
		async getAllPageOrders({ targetProgramId, filters={} }){
			if(!targetProgramId) return;

			if(!Object.keys(filters).length){
				filters = this.getSubmitDetailFilterData();
			}

			try {
				const res = await api.postData({
					model: `order/ids`,
					data: {
						targetProgramId,
						filters,
					}
				});
				console.log('getAllPageOrders', res);
				if(res?.data) return res.data;
				return [];
			} catch (error) {
				console.error(error);
			}

			return;
		}
	},
});
