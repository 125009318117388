import { defineStore } from 'pinia';
import dayjs from 'dayjs';

import helper from '@/app/utils/helper';
import api from '@/app/utils/service-api';

/**
 * @typedef {import('@/app/components/common-comps/chat-panel/chat-feed/types/types-chat-feed').IChatGid} IChatGid
 */

export const useChatStore = defineStore('chatStore', {
	state() {
		return {
			unreadCountMessage: 0,
			unreadMessageData: [],
			readMessageData: [
				{
					"id": 68,
					"notificationId": 54,
					"notificationType": "tp_row_off_changed_needs_approval",
					"notification": {
						"body": "Автоматическое снятие резервов<br>",
						"actions": [],
						"targetProgram": {
							"id": "84",
							"url": "http://localhost/target-programs/84",
							"name": "РФ: Плантация (2024)"
						},
						"notificationTime": "2025-02-27T08:33:13.210Z",
						"linesInTargetProgram": 0,
						"initiator": {
							"type": 'system',
							"name": "Система",
							"email": "",
							"tg_id": "",
							"last_name": "",
							"middle_name": null,
							"phone": "",
							"photo": "/special-avatar.jpg"
						},
						"gidsData": [
							{
								"gid": "ALM-BB-00054-B",
								"month": "2025-01",
								"orderId": 32186
							},
							{
								"gid": "KZN-CBRD-00623-A",
								"month": "2025-01",
								"orderId": 32931
							},
							{
								"gid": "KRS-BB-00028-A",
								"month": "2025-01",
								"orderId": 33251
							},
							{
								"gid": "CHEL-BB-00819-A",
								"month": "2025-02",
								"orderId": 33407
							},
							{
								"gid": "CHEL-BB-00822-A",
								"month": "2025-02",
								"orderId": 33461
							},
							{
								"gid": "ALM-BB-00054-B",
								"month": "2025-01",
								"orderId": 32186
							},
							{
								"gid": "KZN-CBRD-00623-A",
								"month": "2025-01",
								"orderId": 32931
							},
							{
								"gid": "KRS-BB-00028-A",
								"month": "2025-01",
								"orderId": 33251
							},
							{
								"gid": "CHEL-BB-00819-A",
								"month": "2025-02",
								"orderId": 33407
							},
							{
								"gid": "CHEL-BB-00822-A",
								"month": "2024-12",
								"orderId": 98069
							},
						]
					}
				},
			],
			/** @type {IChatGid | null} */
			openGidData: null,
		}
	},

	getters: { },
	actions: {
		updateUnreadCountMessage(count=0){
			this.unreadCountMessage = Number(count);
		},
		async getUnreadCount(){
			try {
				const res = await api.getData({ model: 'notifications-center/log-chat/unread-count' });
				console.log('getUnreadCount-Chat', res);

				if(res?.count) this.unreadCountMessage = res.count;
				return res;
			} catch (error) {
				console.log(error);
			}
			return;
		},
		/** Запрос непрочитанных сообщений */
		async getUnreadMessage(){
			try {
				const res = await api.getData({ model: 'notifications-center/log-chat/unread' });
				console.log('Chat-getUnreadMessage', res);
				if(res) this.unreadMessageData = res;

				return res;
			} catch (error) {
				console.log(error);
			}
			return;
		},
		/** Запрос прочитанных сообщений */
		async getReadMessage(){
			try {
				const res = await api.getData({ model: 'notifications-center/log-chat' });
				console.log('Chat-getReadMessage', res);
				if(res) this.readMessageData = res;

				return res;
			} catch (error) {
				console.log(error);
			}
			return;
		},

		async checkMessageRead(notificationsIds=[]){
			if(!notificationsIds.length) notificationsIds = this.unreadMessageData.map((item)=>item.notificationId);

			try {
				const res = await api.postData({
					model: 'notifications-center/log-chat/read',
					data: { notificationsIds },
				});
				console.log('checkMessageRead', res);
				return res;
			} catch (error) {
				console.error(error);
			}
			return;
		}
	},
});