<template lang="pug">

ElMain.bs-main
	slot

</template>

<script>
import { ElMain } from 'element-plus';
import 'element-plus/es/components/main/style/css';

export default {
	name: 'bs-main',
	components: {
		ElMain
	},
}
</script>
<style lang="scss">
.bs-main{
	--el-main-padding: 0;
	overflow: unset;
}
</style>
