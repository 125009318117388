import { Component, Vue, toNative, Prop, Watch } from 'vue-facing-decorator';

import occ from '@/app/utils/occupancy';
import helper from '@/app/utils/helper';

import { BaseEmployInfo } from '../base-employ-info';
import SurfaceMonthItem from '../surface-month-item/SurfaceMonthItem.vue';

@Component({
	name: 'EmployInfoStaticV2',
	components: {
		SurfaceMonthItem,
	},
})
class EmployInfoStaticV2 extends BaseEmployInfo {
	override get occupancy(){
		const { staticOccupancy, occupanciesDaily } = this.data as { staticOccupancy, occupanciesDaily };
		const placePeriods: Record<string, string[]> = helper.splitPeriodByMonths(this.period.from, this.period.to) as Record<string, string[]>;
		let occupancy = {};

		// если приходит пустой градусник или в виде пустого массива
		if (!occupanciesDaily || (Array.isArray(occupanciesDaily) && !occupanciesDaily?.length)) {
			for (const item of this.monthList) {
				const { date } = item;
				occupancy[date] = {
					status: 'free',
					added: this.isCheckPeriod(date),
				};
			}
		}
		else for(const month of this.monthList){
			const yearMonth = month.date;
			const monthData = occupanciesDaily?.[yearMonth];
			const added = this.isCheckPeriod(yearMonth);

			if(!monthData){
				occupancy[yearMonth] = {
					status: 'free',
					added,
				};
				continue;
			}

			let countDayFree = 0;
			let countDayReserved = 0;
			let statusCalendar: Record<string, string> = null;
			let monthStatusSet: Set<string> = null;

			const placePeriodData = placePeriods?.[yearMonth];
			if(placePeriodData){
				monthStatusSet = new Set();
				statusCalendar = occ.getStaticMonthCalendar({ monthData }, (dayData)=>{
					const { dayDate, free, reserved, busy, unavailable, status } = dayData;
					if(!placePeriodData.includes(dayDate)) return;

					if(free) countDayFree++;
					if(reserved) countDayReserved++;
				});
				for(const dayDate of placePeriodData){
					const status = statusCalendar?.[dayDate];
					if(status) monthStatusSet.add(status);
				}

				// console.log({monthStatusSet, statusCalendar, placePeriodData});
			} else {
				statusCalendar = occ.getStaticMonthCalendar({ monthData }, (dayData)=>{
					const { free, reserved, busy, unavailable, status } = dayData;
					if(free) countDayFree++;
					if(reserved) countDayReserved++;
				});
				monthStatusSet = new Set(Object.values(statusCalendar));
			}

			let data = {};
			if(monthStatusSet.size == 1){
				if(monthStatusSet.has('free')){
					data = {
						status: 'free',
						value: 0
					};
				}
				else if(monthStatusSet.has('reserved')){
					data = {
						status: 'reserved',
						valueOne: countDayReserved,
					};
				}
				else if(monthStatusSet.has('free-reserved')){
					data = {
						chunks: [
							{
								status: 'free',
								valueOne: countDayFree,
							},
							{
								status: 'reserved',
								valueOne: countDayReserved,
							},
						]
					};
				}
				else if(monthStatusSet.has('mixed')){
					data = {
						status: 'mixed',
						valueOne: (countDayFree + countDayReserved),
					};
				}
				else if(monthStatusSet.has('disabled')){
					data = {
						status: 'disabled',
						value: 0
					};
				}
				else if(monthStatusSet.has('busy')){
					data = {
						status: 'busy',
						value: 0
					};
				}
			}
			else if(monthStatusSet.size == 2 && monthStatusSet.has('free') && monthStatusSet.has('reserved')){
				data = {
					chunks: [
						{
							status: 'free',
							valueOne: countDayFree,
						},
						{
							status: 'reserved',
							valueOne: countDayReserved,
						},
					]
				};
			}
			else {
				if((monthStatusSet.has('busy') || monthStatusSet.has('disabled') || monthStatusSet.has('mixed'))
					&& (monthStatusSet.has('free') || monthStatusSet.has('reserved') || monthStatusSet.has('free-reserved'))){
					data = {
						status: 'mixed',
						valueOne: (countDayFree + countDayReserved),
					};
				}
				else if(monthStatusSet.has('free') || monthStatusSet.has('reserved')){
					data = {
						status: 'reserved',
						valueOne: countDayReserved,
					};
				}
			}
			// console.log({data});

			occupancy[yearMonth] = {
				...data,
				added,
			};
		}

		// console.log({occupancy});
		return occupancy;
	}
}

export default toNative(EmployInfoStaticV2);