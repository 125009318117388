import { defineComponent } from 'vue';

import { ElContainer } from 'element-plus';
import BsHeaderAuth from '@/app/layouts/parts/headers/bs-header-auth/BsHeaderAuth.vue';
import BsAside from '@/app/layouts/parts/bs-aside/BsAside.vue';
import BsMain from '@/app/layouts/parts/bs-main/BsMain.vue';
import FooterAuth from '@/app/layouts/parts/footers/footer-auth/FooterAuth.vue';

export default defineComponent({
	name: 'auth-layout',
	components: {
		ElContainer,
		BsHeaderAuth, BsAside, BsMain,
		FooterAuth,
	},
	computed: {
		headTitle() {
			return this.$route?.meta?.title ?? '';
		}
	}
});