import { defineComponent } from "vue";

export default defineComponent({
	name: 'surface-month-item',
	props: {
		data: {
			type: Object,
			default: ()=>({}),
		},
		isDigital: Boolean,
		isHover: Boolean,
	},
	data() {
		return {

		}
	},
	computed:{
		type(){
			if(this.data?.chunks) return 'multi';
			return 'single';
		},
		status(){
			return this.data?.status || '';
		},
		chunkList(){
			return this.data.chunks;
		},
		classRootList(){
			return {
				'--is-digital': this.isDigital,
				'--is-static': !this.isDigital,
				'--is-hover': this.isHover
			};
		},
		classTypeList(){
			return {
				'type-single': this.type=='single',
				'type-multi': this.type=='multi',
			};
		},
		classStatusList(){
			if(this.type=='multi') return {};

			return {
				'status-free': this.status=='free',
				'status-reserved': this.status=='reserved',
				'status-busy': this.status=='busy',
				'status-disabled': ['dismounted', 'disabled'].includes(this.status),
				'status-partly': this.status=='mixed',
				'type-added': this.data.added,
			};
		},
	}
})