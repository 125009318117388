import mixinNotifMessage from '../mixins/mixin-notif-message';

import BsBtn from "@/app/components/controls/buttons/bs-btn/BsBtn.vue";
import NotifSystemInfo from '@/app/components/notification/other/notif-system-info/NotifSystemInfo.vue';

export default {
	name: 'NotifMessageSystem',
	mixins: [mixinNotifMessage],
	components: {
		BsBtn,
		NotifSystemInfo,
	},
	props: {
		data: {
			type: Object,
			default: () => ({
				id: '',
				targetProgramId: '',
				createAt: '',
				senderType: '',
				notifType: '',
				isRead: true,
				message: '',
			}),
		},
		index: {
			type: Number,
			required: true
		}
	},
	data() {
		return {}
	},
	emits: ['showApprovePopup'],
	methods: {
		redirect(url) {
			window.location.href = url;
		},
		showApprovePopup(type) {
			this.$emit('showApprovePopup', {index: this.index, type: type})

		},
		getButtonStyles(bgColor) {
			const isDark = this.isDarkColor(bgColor);

			return {
				backgroundColor: bgColor,
				color: isDark ? '#fff' : '#000',
			};
		},

		isDarkColor(color) {
			const hex = color.replace('#', '');

			const r = parseInt(hex.substring(0, 2), 16);
			const g = parseInt(hex.substring(2, 4), 16);
			const b = parseInt(hex.substring(4, 6), 16);
			const brightness = (0.299 * r + 0.587 * g + 0.114 * b);

			return brightness < 128;
		},
	}
}