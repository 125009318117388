import { defineComponent } from "vue";
import mixinMarkerType from "../mixin-marker-type";

import SvgIcon from "@/app/components/svg-icon/SvgIcon.vue";

export default defineComponent({
	name: 'MarkerTypeAngular',
	mixins: [ mixinMarkerType ],
	components: {
		SvgIcon,
	},
});